import { AppHeader, Dialogs, PrimaryCard, utils } from "@ucl/library";
import { Environment } from "@uix/common";
import { Notifications } from "@uix/common/src/components/Notifications";
import React from "react";
import { ErrorBoundary, FallbackProps } from "react-error-boundary";
import {
  Navigate,
  useMatches,
  useOutlet,
  useParams,
  useRouteLoaderData,
} from "react-router";
import logo from "../../public/assets/title-logo.png";
import { Footer } from "../components/Footer/Footer";
import { injector } from "../injector";
import "./styles.scss";
import { UnderwriteData } from "../types/underwrite";
import { consumerPageRouteId } from "./useUnderwritePageLoaderData";

export interface PagesLayoutComponentProps {
  redirectPath: string;
}

const env = injector.get(Environment);

const PagesLayoutComponent: React.FC<PagesLayoutComponentProps> = (props) => {
  const outlet = useOutlet();
  const params = useParams();
  const matches = useMatches();
  const currentRoute = matches[matches.length - 1];

  const isConsumerRoute = currentRoute.pathname.includes(
    `/${consumerPageRouteId}`
  );

  // Fet data from the route loader
  let data = useRouteLoaderData(currentRoute.id) as UnderwriteData;
  if (!data && isConsumerRoute) {
    data = useRouteLoaderData(consumerPageRouteId) as UnderwriteData;
  }

  // Set Application Name for Header
  const applicantName = `${data?.solution?.primaryApplicant.firstName || ""} ${
    data?.solution?.primaryApplicant.lastName || ""
  }`.trim();

  const ErrorFallback = (args: FallbackProps) => {
    return (
      <PrimaryCard headerContent="Something went wrong">
        <div role="alert">
          <pre>{args.error.message}</pre>
          <button onClick={args.resetErrorBoundary}>Try again</button>
        </div>
      </PrimaryCard>
    );
  };

  const AppLogo = () => {
    return <img className="app-logo" src={logo} alt="RelativityX" />;
  };

  return (
    <div className="flex flex-col h-screen">
      {isConsumerRoute && (
        <AppHeader
          theme={env.theme}
          appLogo={<AppLogo />}
          appTitle={applicantName}
        />
      )}
      {!isConsumerRoute && (
        <AppHeader
          theme={env.theme}
          appLogo={<AppLogo />}
          breadcrumbSettings={{
            uclRouteObject: [
              {
                label: "UnderwriteX",
                path: "/underwritex",
                showInNavigation: true,
                showInSubNavigation: true,
                children: [
                  {
                    path: `/${params.thirdPartyKey}`,
                    label: applicantName,
                    showInNavigation: true,
                    showInSubNavigation: true,
                  },
                ],
              },
            ] as utils.routes.UclRouteObject[],
          }}
        >
          <Notifications />
        </AppHeader>
      )}
      <div className="prose max-w-none overflow-y-auto overflow-x-hidden">
        <ErrorBoundary FallbackComponent={ErrorFallback}>
          {outlet || <Navigate to={props.redirectPath} replace />}
        </ErrorBoundary>
        <Dialogs />
      </div>

      <Footer />
    </div>
  );
};

export const PagesLayout = PagesLayoutComponent;
