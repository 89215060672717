import { Injectable } from "injection-js";
import { NotificationLevel } from "./notificationLevel";
import { Environment } from "../environment";
import { HttpError } from "./baseClient";

/** A notification to show to the user. */
export class Notification {
  /** How important the notification is. */
  public readonly level: NotificationLevel;
  /** Text to display to the user. */
  public readonly message: string;
  /** Additional context for the error which is logged to the console. */
  public readonly consoleContext?: object;

  constructor(level: NotificationLevel, message: string, consoleContext?: object) {
    this.level = level;
    this.message = message;
    this.consoleContext = consoleContext;
  }
}

/** A function which gets called every time a new notification is published. */
export type NotificationCallback = (notification: Notification) => Promise<void>;

/** Allows `Notification` objects to be published and subscribed to. */
@Injectable()
export class NotificationsService {
  private readonly notifications: Notification[] = [];
  private readonly callbacks: NotificationCallback[] = [];
  private readonly minNotificationLevel: NotificationLevel;

  /**
   * The maximum number of notifications which can be stored in this service. If more than this
   * number are published, then the oldest ones are deleted.
   */
  public maxNotifications = 10;

  constructor(env: Environment) {
    this.minNotificationLevel = env.minNotificationLevel;
  }

  /** 
   * Publishes a notification to all registered callbacks. The promise returned by this method
   * completes when all callbacks complete. Note that the order the callbacks are called is
   * indeterminate.
   */
  public async publish(notification: Notification): Promise<void> {
    if (notification.level < this.minNotificationLevel) return;
    this.logContext(notification.level, notification.consoleContext);
    this.notifications.push(notification);
    if (this.notifications.length > this.maxNotifications) {
      this.notifications.shift();
    }
    const promises = this.callbacks.map(callback => callback(notification));
    await Promise.allSettled(promises);
  }

  /**
   * Publishes a notification at the error level with the given message and which uses the given
   * error object as its context. If an {@link HttpError} is given, its
   * body property is used as context.
   * @param message
   * @param error
   */
  public async publishError(message: string, error?: Error): Promise<void> {
      const context =
        error instanceof HttpError ? error.body : error;
      await this.publish(
        new Notification(NotificationLevel.Error, message, context)
      );
  }

  /** Logs the given context object to the console at the appropriate level. */
  private logContext(level: NotificationLevel, context?: object) {
    if (!context) return;
    switch (level) {
      case NotificationLevel.Fatal:
        console.error(context);
        break;
      case NotificationLevel.Error:
        console.error(context);
        break;
      case NotificationLevel.Warn:
        console.warn(context);
        break;
      case NotificationLevel.Info:
        console.info(context);
        break;
      case NotificationLevel.Debug:
        console.debug(context);
        break;
      default:
        throw new Error(`Unexpected notification level: '${level}'.`);
    }
  }

  /** Registers the given function to be called when new notifications are published. */
  public register(callback: NotificationCallback) {
    this.callbacks.push(callback);
  }

  /** Removes the given callback from the registry, so that it will not be called again. */
  public deregister(callback: NotificationCallback) {
    const index = this.callbacks.findIndex(e => e === callback);
    if (index < 0) return;
    this.callbacks.splice(index, 1);
  }

  /** Returns an array of the `Notification` objects currently stored in this service. */
  public getNotifications(): ReadonlyArray<Notification> {
    return this.notifications;
  }

  /** Removes all notifications currently stored in this service. */
  public clearNotifications() {
    this.notifications.splice(0, this.notifications.length);
  }
}