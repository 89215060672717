import CustomerProfile from "./CustomerProfile/CustomerProfile";
import { CustomerFinInsightsDisplaySettings } from "./FinInsights/CustomerFinInsights";
import FinInsights, { FinInsightsPage } from "./FinInsights/FinInsights";
import { SalesSettings } from "@uix/common/src/domain/messages/sales-settings";

export interface RightBarProps {
  salesSettings?: SalesSettings;
  defaultTab?: FinInsightsPage;
  customerDisplaySettings: CustomerFinInsightsDisplaySettings;
}

export const RightBar: React.FC<RightBarProps> = ({
  salesSettings,
  defaultTab,
  customerDisplaySettings,
}) => {
  return (
    <div
      style={{ borderLeftWidth: 1, borderLeftColor: "#DBDFE7" }}
      className="h-full w-full"
    >
      <div className="collapse collapse-arrow">
        <input type="radio" name="my-accordion-1" />
        <h2 className="collapse-title border-b-2 rounded-none ml-2">
          Customer Profile
        </h2>
        <div className="collapse-content">
          <CustomerProfile />
        </div>
      </div>
      <div className="collapse collapse-arrow">
        <input type="radio" name="my-accordion-1" defaultChecked />
        <h2 className="collapse-title border-b-2 rounded-none ml-2">
          Financial Insights
        </h2>
        <div className="collapse-content">
          <FinInsights
            defaultTab={defaultTab}
            salesSettings={salesSettings}
            customerDisplaySettings={customerDisplaySettings}
          />
        </div>
      </div>
    </div>
  );
};

export default RightBar;
