import { InputHTMLAttributes } from "react";

export interface CheckboxProps extends InputHTMLAttributes<HTMLInputElement> {
  label?: string;
}

const Checkbox = (props: CheckboxProps) => (
  <div className="w-full flex gap-2">
    <input
      className="
          peer relative appearance-none shrink-0 w-4 h-4 border-2 border-cc-skyblue-light rounded-sm mt-1 bg-white
          focus:outline-none focus:ring-offset-0 focus:ring-1 focus:ring-cc-skyblue
          checked:bg-cc-skyblue checked:border-0
          disabled:border-steel-400 disabled:bg-steel-
          cursor-pointer
        "
      type="checkbox"
      {...props}
    />
    <svg
      className="absolute w-4 h-4 pointer-events-none hidden peer-checked:block stroke-white mt-1 outline-none"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      fill="none"
      stroke="currentColor"
      strokeWidth="4"
      strokeLinecap="round"
      strokeLinejoin="round"
    >
      <polyline points="20 6 9 17 4 12"></polyline>
    </svg>
    {props.label && <label className="text-body1 body1-m">{props.label}</label>}
  </div>
);

export default Checkbox;
