import { PrimaryButton } from "@ucl/library";
import { PrimaryButtonProps } from "@ucl/library/lib/components/Buttons/PrimaryButton";
import { useState } from "react";
import { wrapClickHandler } from "./buttonsModule";

export interface PrimaryButtonAsyncProps
  extends Omit<PrimaryButtonProps, "onClick"> {
  onClick: (event: React.MouseEvent<HTMLElement>) => Promise<void>;
}

export const PrimaryButtonAsync: React.FC<PrimaryButtonAsyncProps> = (
  props
) => {
  const [isLoading, setLoading] = useState(false);
  const onClick = wrapClickHandler(props.onClick, setLoading);
  const newProps: PrimaryButtonProps = { ...props, onClick, isLoading, enableProgress: false };
  return <PrimaryButton {...newProps} />;
};

export default PrimaryButtonAsync;
