/** Functions for working with the `Address` type. */

import { Address } from "../domain/messages/address";

/** Returns the full zip code of the given address, meaning it includes th +4 part, if present. */
export function addressFullZip(address: Address): string {
  return address.zip4
    ? `${address.zip}-${address.zip4}`
    : address.zip?.toString() ?? "";
}

/** Returns the address formatted as a single line string. */
export function addressString(address: Address | undefined): string {
  if (
    undefined === address ||
    undefined === address.addressLine1 ||
    undefined === address.city ||
    undefined === address.state
  )
    return "";
  const parts = [address.addressLine1];
  if (address.addressLine2) parts.push(address.addressLine2);
  parts.push(`${address.city}, ${address.state} ${addressFullZip(address)}`);
  return parts.join(", ");
}
