import { ReactNode } from "react";

export interface FooterProps {
  className?: string;
  children?: ReactNode;
}

export const Footer: React.FC<FooterProps> = () => {
  return (
    <footer className="footer footer-center p-4 flex justify-end px-8">
      <aside>
        <p className="poweredby">
          Powered by{" "}
          <a className="relativityxlink" href="https://relativityx.com">
            RelativityX
          </a>
        </p>
      </aside>
    </footer>
  );
};

export default Footer;
