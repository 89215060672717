import { Provider, ReflectiveInjector } from "injection-js";
import { Environment } from "../environment";
import { ApplicationClient, AuthService } from ".";
import { SecurityHostClient } from "./securityHostClient";
import { UserService } from "./userService";
import { NotificationsService } from "./notificationService";
import { Context, createContext } from "react";
import { SolutionClient } from "./solutionClient";
import { SettingsClient } from "./settingsClient";

/** A type which can be used to create an instance of `T`. */
export type Constructor<T> = { new (...args: any[]): T };

function defaultServices(): Constructor<any>[] {
    return [
        Environment,
        AuthService,
        ApplicationClient,
        SecurityHostClient,
        UserService,
        NotificationsService,
        SolutionClient,
        SettingsClient
    ];
}

/** Type which helps in constructing an `Injector`. */
export class InjectorBuilder {
    private readonly registry = defaultServices();

    /**
     * Registers a new service of type `T`.
     * @param service
     */
    public addService<T>(service: Constructor<T>): void {
        this.registry.push(service);
    }

    /**
     * Returns an `Injector` which can be used to resolve service instances.
     */
    public build(): Injector {
        const inner = ReflectiveInjector.resolveAndCreate(<Provider[]>this.registry);
        return new Injector(inner);
    }
}

/** A dependency injection container. */
export class Injector {
    constructor(
        private readonly inner: ReflectiveInjector
    ) {}

    /** Returns an instance of `T`, if `T` has been registered in this injector.
     * Otherwise, `ClassNotFoundError<T>` is thrown.
     */
    public get<T>(ctor: Constructor<T>): T {
        const output = this.inner.get(ctor, null);
        if (!output)
            throw new ServiceNotRegistered(ctor);
        return output;
    }
}

/** An error which occurs when the type `T` has not been registered as a service. */
export class ServiceNotRegistered<T> extends Error {
    constructor(
        /** The type that was requested. */
        public readonly ctor: Constructor<T>
    ) {
        super(`No service registration found for class '${ctor.name}'.`);
    }
}

/**
 * A React context which allows an `Injector` object to be passed to child components.
 * Note that the default value of this context is `undefined`.
 */
export const InjectorContext = createContext(undefined) as Context<Injector | undefined>;