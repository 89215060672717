import Dollar from "@uix/common/src/components/Dollar";
import { SalesSettings } from "@uix/common/src/domain/messages/sales-settings";
import {
  totalInterest,
  totalSales,
} from "@uix/common/src/modules/solutionModule";
import { useContext } from "react";
import { SelectionContext } from "../../../pages/BankerPage/SelectionContext";

export interface BankerFinInsights {
  salesSettings: SalesSettings;
}

export const BankerFinInsights: React.FC<BankerFinInsights> = ({
  salesSettings,
}) => {
  const { selected } = useContext(SelectionContext);
  const solution = selected?.solution;

  const sales = solution ? totalSales(solution) : 0;
  const interest = solution ? totalInterest(solution) : 0;
  const commissionRate = salesSettings.commissionRate;
  const commission = (commissionRate * sales) / 100;

  return (
    <>
      <h5 className="px-4 pb-3">With the current selection:</h5>

      <div className="px-4 py-3 flex flex-row justify-between border-b-2 body1 text-dark-gray1">
        <span>Total Sales:</span>
        <Dollar value={sales} colorBySign={false} />
      </div>

      <div className="px-4 py-3 flex flex-row justify-between border-b-2 body1 text-dark-gray1">
        <span>Total Interest Income:</span>
        <Dollar value={interest} colorBySign={false} />
      </div>

      <div className="px-4 py-3 flex flex-row justify-between body1 text-dark-gray1">
        <span>Estimated Total Commission:</span>
        <Dollar value={commission} colorBySign={false} />
      </div>
    </>
  );
};

export default BankerFinInsights;
