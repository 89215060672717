import DecisionComponent from "./DecisionComponent";
import { useState } from "react";
import { RefinanceOption } from "@uix/common/src/domain/messages/refinance-option";
import { useUnderwritePageLoaderData } from "../useUnderwritePageLoaderData";

export const DecisionPage: React.FC = () => {
  const { underwriteData } = useUnderwritePageLoaderData();
  const [, setSelected] = useState<RefinanceOption | undefined>(undefined);

  return (
    <>
      <DecisionComponent
        refis={underwriteData.refis}
        setSelection={setSelected}
      />
    </>
  );
};

export default DecisionPage;
