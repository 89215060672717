/** Wraps the inner click handler and manages the loading state of the button its attached to. */
export function wrapClickHandler(
  innerHandler: (event: React.MouseEvent<HTMLElement>) => Promise<void>,
  setLoading: (isLoading: boolean) => void
): (event: React.MouseEvent<HTMLElement>) => Promise<void> {
  return async (event) => {
    setLoading(true);
    try {
      await innerHandler(event);
    } finally {
      setLoading(false);
    }
  };
}
