import ClaimsTest from "./ClaimsTest";
import ApplicationTest from "./ApplicationTest";
import RefisTest from "./RefisTest";
import SolutionTest from "./SolutionTest";

const DebugPageComponent: React.FC = () => {
  return (
    <>
      <SolutionTest></SolutionTest>
      <ApplicationTest></ApplicationTest>
      <RefisTest></RefisTest>
      <ClaimsTest></ClaimsTest>
    </>
  );
};

export default DebugPageComponent;
