import { SalesSettings } from "@uix/common/src/domain/messages/sales-settings";
import CrossSellCard from "./CrossSellCard";
import SelectedProductsCard from "./SelectedProductsCard";
import SolutionCard from "./SolutionCard";
import { useOutlet } from "react-router";
import { RefiTableDisplaySettings } from "../RefiTable/RefiTable";

export interface RecommendationsProps {
  salesSettings: SalesSettings | undefined;
  displaySettings?: RefiTableDisplaySettings & {
    crossSellTitle?: string;
    solutionTitle?: string;
    selectedProductsTitle?: string;
  };
}

export const Recommendations: React.FC<RecommendationsProps> = ({
  salesSettings,
  displaySettings,
}) => {
  const outlet = useOutlet();
  return (
    <>
      <div>
        <SelectedProductsCard
          displaySettings={displaySettings}
          salesSettings={salesSettings}
        />
        <br />
        <SolutionCard
          title={displaySettings?.solutionTitle}
          displaySettings={displaySettings}
          salesSettings={salesSettings}
        />
        <br />
        <CrossSellCard
          title={displaySettings?.crossSellTitle}
          displaySettings={displaySettings}
          salesSettings={salesSettings}
        />
      </div>
      {outlet}
    </>
  );
};

export default Recommendations;
