import { RefinanceOption } from "@uix/common/src/domain/messages/refinance-option";
import { ChangeEventHandler } from "react";

export interface TermFormatterProps {
  options: RefinanceOption[];
  selectedOption: RefinanceOption;
  handleChange: ChangeEventHandler<HTMLSelectElement>;
  selectedOptionReferenceId: string | undefined;
  totalTerm: number | undefined;
}

const TermFormatter: React.FC<TermFormatterProps> = ({
  options,
  selectedOption,
  handleChange,
  selectedOptionReferenceId,
  totalTerm,
}) => {
  const similarProductsWithDifferentTerms = options.filter(
    (refi) =>
      refi.refinanceProduct.name === selectedOption.refinanceProduct.name
  );
  return (
    <div className="flyout-drawer-item-row">
      <h5 className="flyout-drawer-item-title">Term</h5>
      {similarProductsWithDifferentTerms.length < 2 ? (
        <span className="flyout-drawer-item-value">{`${totalTerm} Months`}</span>
      ) : (
        <select
          onChange={handleChange}
          value={selectedOptionReferenceId}
          className="select select-bordered select-sm w-full max-w-xs bg-white"
        >
          {similarProductsWithDifferentTerms?.map((refi, k) => (
            <option key={k} value={refi.referenceId}>
              {refi.refinanceLiability.debtInstrument?.phases[0].term}
              &nbsp;Months
            </option>
          ))}
        </select>
      )}
    </div>
  );
};

export default TermFormatter;
