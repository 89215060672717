import { useState } from "react";
import { PrimaryCard, SecondaryCard } from "@ucl/library";
import SecondaryButtonAsync from "@uix/common/src/components/Buttons/SecondaryButtonAsync";
import { injector } from "../../injector";
import { ApplicationClient } from "@uix/common";
import { NotificationsService } from "@uix/common/src/services/notificationService";

const appClient = injector.get(ApplicationClient);
const notifications = injector.get(NotificationsService);

interface State {
  appId: string;
  refisJson: string;
}

function initialState(): State {
  return { appId: "", refisJson: "" };
}

export const RefisTest: React.FC = () => {
  const [state, setState] = useState(initialState());

  function appIdChange(event: React.FormEvent<HTMLInputElement>): void {
    const appId = event.currentTarget.value;
    setState({ ...state, appId });
  }

  async function load(): Promise<void> {
    const appId = state.appId;
    const result = await appClient.getRefis({ appId });
    if (!result.isSuccess) {
      const message = `Failed to get refinancing options for application ${appId}: ${result.error}`;
      await notifications.publishError(message, result.error);
      return;
    }
    const refisJson = JSON.stringify(result.value, null, 2);
    setState({ ...state, refisJson });
  }

  return (
    <PrimaryCard headerContent="Refinancing Options Test">
      <p>
        Enter an application ID below, then click Load to see its refinancing
        options.
      </p>
      <div className="flexit">
        <input type="text" value={state.appId} onChange={appIdChange} />
        <SecondaryButtonAsync onClick={load} content="Load" />
      </div>
      <SecondaryCard headerContent="Refinancing Options">
        <textarea
          cols={100}
          rows={30}
          value={state.refisJson}
          readOnly
        ></textarea>
      </SecondaryCard>
    </PrimaryCard>
  );
};

export default RefisTest;
