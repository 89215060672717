import { RefinanceOption } from "@uix/common/src/domain/messages/refinance-option";
import { useEffect, useState } from "react";
import { DecisionComponentProps } from "./DecisionComponent";
import { loanGroupDisplayName } from "@uix/common/src/modules/loanGroupingModule";
import { orderBy } from "lodash";

export interface RefinanceDecisionOption extends RefinanceOption {
  isRecommended: boolean;
}

export const useDecisionComponent = (props: DecisionComponentProps) => {
  const [selected, setSelected] = useState<RefinanceOption | undefined>(
    undefined
  );
  const [requestedLoanOptions, setRequestedLoanOptions] = useState<
    RefinanceDecisionOption[][]
  >([]);

  useEffect(() => {
    // Initialize requestedLoanOptions
    const requestedLoanOptionsAll: RefinanceDecisionOption[] = [];
    // Add Upsell
    props.refis.requestedLoan.upsellOptions.forEach((option) => {
      requestedLoanOptionsAll.push({ ...option, isRecommended: false });
    });
    // Sort from Lowest monthly payment to Highest
    const requestedLoanOptionsFiltered = orderBy(
      requestedLoanOptionsAll,
      ["refinanceLiability.debtInstrument.phases[0].payment"],
      ["asc"]
    );

    // Insert the last item to the first position
    requestedLoanOptionsFiltered.unshift(
      requestedLoanOptionsFiltered.pop() as RefinanceDecisionOption
    );

    // Add Recommended Option and Insert to the second position
    const recommendedOption = {
      ...props.refis.requestedLoan.options.at(0),
      isRecommended: true,
    };
    requestedLoanOptionsFiltered.splice(1, 0, recommendedOption);

    // Split intro three items group
    const requestedLoanOptionsGrouped = requestedLoanOptionsFiltered.reduce(
      (acc, item, index) => {
        const groupIndex = Math.floor(index / 3);
        if (!acc[groupIndex]) {
          acc[groupIndex] = [];
        }
        acc[groupIndex].push(item);
        return acc;
      },
      [] as RefinanceDecisionOption[][]
    );

    setRequestedLoanOptions(requestedLoanOptionsGrouped);
  }, []);

  useEffect(() => {
    if (selected) {
      props.setSelection({ ...selected });
    }
  }, [selected]);

  const handleSelection = (refinanceOptionSelected: RefinanceOption) => {
    if (
      selected?.refinanceProduct.id !==
      refinanceOptionSelected.refinanceProduct.id
    ) {
      setSelected({ ...refinanceOptionSelected });
    }
  };

  const requestedLoanDisplayName = `
    ${loanGroupDisplayName(
      props.refis.requestedLoan.options.at(0)?.refinanceProduct.group
    )} `;

  return {
    handleSelection,
    requestedLoanDisplayName,
    selected,
    requestedLoanOptions,
  };
};
