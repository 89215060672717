import { BaseClient } from "./baseClient";
import { SettingsKey } from "../domain/messages/settings-key";
import { Result } from "../domain/result";

export class SimpleSettingsClient extends BaseClient {
  constructor(lipBaseUrl: string, apiKey?: string) {
    super(BaseClient.uriJoin(lipBaseUrl, "settings"), undefined, apiKey);
  }

  public getSettings(key: SettingsKey): Promise<Result<Object>> {
    return this.get(undefined, { key });
  }
}