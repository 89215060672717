import { formatDollars } from "../modules/numberModule";

export interface DollarProps {
  /** The dollar value to display. */
  value: number;
  /** Whether the component should change color based on the sign of `value`. The default is true. */
  colorBySign?: boolean;
}

/** A component for displaying a number as dollars. */
export const Dollar: React.FC<DollarProps> = (props) => {
  const { value } = props;
  const colorBySign = props.colorBySign ?? true;
  const formatted = formatDollars(value);
  const spanProps = colorBySign
    ? {
        style: { color: value >= 0 ? "green" : "red" },
      }
    : {};
  return <span {...spanProps}>{formatted}</span>;
};

export default Dollar;
