import { BaseClient, IQueryParams } from "./baseClient";
import { Solution } from "../domain/messages/solution";
import { Result } from "../domain/result";

/**
 * A query for a {@link Solution} object. If both `solutionId` and `thirdPartyKey`
 * are supplied, `solutionId` takes priority. It is an error to specify neither.
 */
export interface SolutionQuery {
  /** The ID of the solution to load. */
  solutionId?: string;
  /** The third party key of the application, whose most recent solution is to be loaded. */
  thirdPartyKey?: string;
}

export class SimpleSolutionClient extends BaseClient {
  constructor(lipBaseUrl: string, apiKey: string) {
    super(BaseClient.uriJoin(lipBaseUrl, "solution"), undefined, apiKey);
  }

  public async getSolution({
    solutionId,
    thirdPartyKey,
  }: SolutionQuery): Promise<Result<Solution>> {
    const query: Result<IQueryParams> =
      solutionId !== undefined
        ? Result.Success({ solutionId })
        : thirdPartyKey !== undefined
        ? Result.Success({ thirdPartyKey })
        : Result.Error("solutionId or thirdPartyKey must be specified");
    if (!query.isSuccess) return query.castError();
    const result = await this.get(undefined, query.value);
    return result as Result<Solution>;
  }

  public async putSolution(solution: Solution): Promise<Result<void>> {
    const result = await this.put(solution);
    return result as unknown as Result<void>;
  }

  public async preApproval(solution: Solution): Promise<Result<boolean>> {
    const result = await this.post(solution, "preapproval");
    return result as Result<boolean>;
  }
}
