import { Refinance } from "@uix/common/src/domain/messages/refinance";
import { SalesSettings } from "@uix/common/src/domain/messages/sales-settings";
import { liabilityHashes } from "@uix/common/src/modules/liabilityModule";
import {
  byDescendingRank,
  conversionRateFunction,
  isCrossSell,
  isUpsell,
} from "@uix/common/src/modules/solutionModule";
import { useContext } from "react";
import {
  SelectedSolution,
  SelectionContext,
} from "../../pages/BankerPage/SelectionContext";
import RefiRow from "./RefiRow";

export interface RefiTableDisplaySettings {
  showInterestRate?: boolean;
  showEstConversionRate?: boolean;
  showEstCommission?: boolean;
  showEstMonthlyPayment?: boolean;
  showRecommendedTag?: boolean;
  showUpsellTag?: boolean;
  showCrossSellTag?: boolean;
  interestRateTitle?: string;
}
export interface RefiTableProps {
  salesSettings: SalesSettings | undefined;
  requestedLoan?: readonly Refinance[];
  refis?: readonly Refinance[];
  selectable?: boolean;
  displaySettings?: RefiTableDisplaySettings;
}

export class RefiInfo {
  public readonly refi: Readonly<Refinance>;
  public readonly isSelected: boolean;
  public readonly isRecommended: boolean;
  public readonly isUpsell: boolean;
  public readonly isCrossSell: boolean;
  /** The hash of the `refi`'s original liabilities. */
  public readonly originalHash: number;
  public readonly requestedLoan?: readonly Refinance[];

  constructor(
    selected: SelectedSolution | undefined,
    refi: Readonly<Refinance>,
    requestedLoan?: readonly Refinance[]
  ) {
    this.refi = refi;
    this.isSelected = selected?.isSelected(refi) ?? false;
    this.requestedLoan = requestedLoan;
    this.originalHash = liabilityHashes(refi.originalLiabilities);
    this.isUpsell = isUpsell(refi, requestedLoan || []);
    this.isCrossSell = isCrossSell(refi, requestedLoan || []);
    this.isRecommended = selected?.isRecommended(refi) ?? false;
  }
}

class GroupInfo {
  public readonly group: readonly RefiInfo[];
  // eslint-disable-next-line no-unused-vars
  public readonly conversionRate: (index: number) => number;

  constructor(group: RefiInfo[]) {
    group.sort((left, right) => byDescendingRank(left.refi, right.refi));
    this.group = group;
    this.conversionRate = conversionRateFunction(group.length);
  }
}

export const RefiTable: React.FC<RefiTableProps> = ({
  requestedLoan,
  refis,
  salesSettings,
  selectable,
  displaySettings,
}) => {
  const { selected } = useContext(SelectionContext);
  // We group the refinance options by their original liabilities, by putting
  // them into arrays based on the hash code of their original liabilities.
  const groups = [];

  const userDisplaySettings = {
    ...({
      showInterestRate: true,
      showEstConversionRate: true,
      showEstCommission: true,
      showEstMonthlyPayment: false,
      showRecommendedTag: true,
      showUpsellTag: true,
      showCrossSellTag: true,
      interestRateTitle: "Interest Rate",
    } as RefiTableDisplaySettings),
    ...displaySettings,
  };

  const recommended = refis?.filter((refi) => selected?.isRecommended(refi));
  if (!!recommended) {
    groups.push(
      new GroupInfo(
        recommended.map((r) => new RefiInfo(selected, r, requestedLoan))
      )
    );
  }

  const mapping = (refis || [])
    // Refis without refinanceProduct are "do nothing" options (keep debt as it).
    .reduce((prev, curr) => {
      if (
        !prev.some(
          (x) =>
            x.refinanceProduct?.name === curr.refinanceProduct?.name &&
            x.refinanceProductSheet?.allowableLoanTerms != undefined &&
            x.refinanceProductSheet?.allowableLoanTerms.length > 1
        )
      )
        prev.push(curr);
      return prev;
    }, [] as Refinance[])
    .filter((refi) => refi.refinanceProduct && !selected?.isRecommended(refi))
    .map((refi) => new RefiInfo(selected, refi, requestedLoan))
    .reduce((prev, curr) => {
      const hash = curr.originalHash;
      prev[hash] ??= [];
      prev[hash].push(curr);
      return prev;
    }, {} as { [hash: number]: RefiInfo[] });

  for (const hash in mapping) {
    if (!mapping.hasOwnProperty(hash)) continue;
    groups.push(new GroupInfo(mapping[hash]));
  }

  return (
    <table className="table w-full my-0">
      <thead>
        <tr className="border-b-light-gray1">
          <th>Product Name/Description</th>
          <th>Amount</th>
          {userDisplaySettings.showInterestRate && (
            <th>{userDisplaySettings.interestRateTitle}</th>
          )}
          <th>Term</th>
          {userDisplaySettings.showEstConversionRate && (
            <th>
              Estimated <br /> Conversion Rate
            </th>
          )}
          {userDisplaySettings.showEstCommission && (
            <th>
              Estimated <br /> Commission
            </th>
          )}
          {userDisplaySettings.showEstMonthlyPayment && (
            <th>
              Est. Monthly <br /> Payment
            </th>
          )}
          {selectable && <th>Selection</th>}
        </tr>
      </thead>
      <tbody>
        {groups.length > 0 ? (
          groups.map(({ group, conversionRate }, outer) =>
            group.map((info, inner) => {
              return (
                <RefiRow
                  key={10000 * outer + inner}
                  inner={inner}
                  info={info}
                  salesSettings={salesSettings}
                  selectable={selectable}
                  conversionRate={conversionRate}
                  displaySettings={userDisplaySettings}
                />
              );
            })
          )
        ) : (
          <tr>
            <td>
              <span>No refinance options.</span>
            </td>
            <td></td>
            {userDisplaySettings.showInterestRate && <td></td>}
            <td></td>
            {userDisplaySettings.showEstConversionRate && <td></td>}
            {userDisplaySettings.showEstCommission && <td></td>}
            {userDisplaySettings.showEstMonthlyPayment && <td></td>}
            {selectable && <td></td>}
          </tr>
        )}
      </tbody>
    </table>
  );
};

export default RefiTable;
