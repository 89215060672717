import { RefinanceOptions } from "@uix/common/src/domain/messages/refinance-options";
import { useEffect, useState } from "react";
import { Alert } from "../../components/Alert/Alert";
import SolutionConfirmation from "../../components/Confirmation/SolutionConfirmation";
import Recommendations from "../../components/Recommendations/Recommendations";
import { FinInsightsPage } from "../../components/RightBar/FinInsights/FinInsights";
import RightBar from "../../components/RightBar/RightBar";
import { SelectionContext } from "../BankerPage/SelectionContext";
import "../styles.scss";
import { useUnderwrite } from "../useUnderwrite";
import { useUnderwritePageLoaderData } from "../useUnderwritePageLoaderData";
import "./styles.scss";
import { useSearchParams } from "react-router-dom";

/*eslint no-unused-vars: "off" */
/* This is needed because eslint it too stupid to understand that Page is actually used. */
enum Page {
  Recommendations,
  Confirmation,
}

export interface ConsumerComponentProps {
  refis: Readonly<RefinanceOptions>;
}
const ConsumerComponent: React.FC<ConsumerComponentProps> = () => {
  const [page, setPage] = useState(Page.Recommendations);
  const { underwriteData } = useUnderwritePageLoaderData();
  const [searchParams] = useSearchParams();
  const selectedHash = searchParams.get("selected");

  const {
    selected,
    submittedSolution,
    context,
    isDenied,
    hasSolutionSelected,
    submitSolution,
  } = useUnderwrite(underwriteData);

  function gotoConfirmation() {
    setPage(Page.Confirmation);
  }

  useEffect(() => {
    if (submittedSolution) {
      gotoConfirmation();
    }
  }, [submittedSolution]);

  const renderBackButton = () => {
    return (
      <div className="mb-5 flex">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="16"
          height="17"
          viewBox="0 0 16 17"
          fill="none"
        >
          <g clipPath="url(#clip0_663_4256)">
            <path
              d="M9.349 6.66577L8.76547 7.25082L6.29434 4.78127L5.4409 3.92838V5.13494L5.4409 10.3324C5.4409 12.899 7.49211 14.9574 10.0552 14.9574H15.498V15.7908L10.0552 15.7908C8.61207 15.7908 7.2278 15.216 6.20697 14.1925C5.18609 13.169 4.61233 11.7805 4.61233 10.3324L4.61233 5.13494V3.92561L3.75832 4.78185L1.28844 7.25815L0.702994 6.66702L5.02662 2.33213L9.349 6.66577Z"
              fill="#0E1624"
              stroke="#4D85EB"
            />
          </g>
          <defs>
            <clipPath id="clip0_663_4256">
              <rect
                width="16"
                height="16"
                fill="white"
                transform="translate(-0.00195312 0.957458)"
              />
            </clipPath>
          </defs>
        </svg>
        <a
          href={document.referrer}
          className="body1 text-cc-skyblue no-underline ml-3"
        >
          &nbsp;Back to LOS
        </a>
      </div>
    );
  };

  useEffect(() => {
    if (selectedHash && !hasSolutionSelected) {
      selected.setSelectedFromHash(Number(selectedHash), context.setSelected);
    }
  }, [selectedHash]);

  return (
    <SelectionContext.Provider value={context}>
      <div className="consumer-container container px-4 py-4 mx-auto">
        {page !== Page.Confirmation && (
          <div className="grid md:grid-cols-10 grid-cols-1 md:gap-4">
            <div className="banker-page-products md:col-span-7">
              <div className="banker-page-products-header">
                {page === Page.Recommendations && (
                  <>
                    {renderBackButton()}
                    <div className="mb-5 flex"></div>
                    <div className="flex md:flex-row flex-col justify-between mb-5">
                      <h2>Finalize Your Selections</h2>
                      <div>
                        <button
                          onClick={submitSolution}
                          className={`btn ${
                            !hasSolutionSelected
                              ? "btn-disabled"
                              : "btn-primary bg-cc-skyblue border-cc-skyblue hover:border-cc-skyblue hover:bg-cc-skyblue hover:no-underline outline-none"
                          }`}
                        >
                          <span className="text-white body1">
                            {"Submit Application >"}
                          </span>
                        </button>
                      </div>
                    </div>
                  </>
                )}
              </div>
              <div className="banker-page-products-content">
                <div>
                  {isDenied && (
                    <Alert>
                      <span>
                        The applicant does not qualify for the selected loans.
                      </span>
                    </Alert>
                  )}
                </div>
                {page === Page.Recommendations && (
                  <Recommendations
                    salesSettings={underwriteData?.salesSettings}
                    displaySettings={{
                      crossSellTitle: "Explore Your Exclusive Offers",
                      solutionTitle: "Our Best Offer",
                      interestRateTitle: "Est. Interest Rate",
                      showEstConversionRate: false,
                      showEstCommission: false,
                      showEstMonthlyPayment: true,
                      showUpsellTag: false,
                      showCrossSellTag: false,
                    }}
                  />
                )}
              </div>
            </div>
            <div className="banker-page-products-summary md:col-span-3 col-span-1 mt-8 mb-0">
              {selected && (
                <RightBar
                  defaultTab={FinInsightsPage.Customer}
                  customerDisplaySettings={{
                    title: "Here's your monthly financial outlook.",
                    dtiUnderLimitValidationMessage:
                      "Your selections are within budget, with a modest margin for the unexpected. Consider your comfort with this monthly surplus for unforeseen needs.",
                    dtiOverLimitValidationMessage:
                      "Your selections exceed the recommended budget, leaving limited room for unexpected expenses. Please review your choices to ensure financial stability and avoid potential strain.",
                  }}
                />
              )}
            </div>
          </div>
        )}
        {page === Page.Confirmation && (
          <div className="banker-page-confirmation">
            <SolutionConfirmation solution={submittedSolution} />
          </div>
        )}
      </div>
    </SelectionContext.Provider>
  );
};

export default ConsumerComponent;
