import { Params, useMatches, useRouteLoaderData } from "react-router";
import { UnderwriteData } from "../types/underwrite";
import { Result } from "@uix/common/src/domain/result";
import { RefinanceOptions } from "@uix/common/src/domain/messages/refinance-options";
import { SalesSettings } from "@uix/common/src/domain/messages/sales-settings";
import { SettingsKey } from "@uix/common/src/domain/messages/settings-key";
import { Solution } from "@uix/common/src/domain/messages/solution";
import { injector } from "../injector";
import {
  ApplicationClient,
  Environment,
  SimpleApplicationClient,
  SimpleSettingsClient,
  SimpleSolutionClient,
} from "@uix/common";
import { NotificationsService } from "@uix/common/src/services/notificationService";
import { SettingsClient } from "@uix/common/src/services/settingsClient";
import { SolutionClient } from "@uix/common/src/services/solutionClient";

const solutionClient = injector.get(SolutionClient);
const notifications = injector.get(NotificationsService);
const settingsClient = injector.get(SettingsClient);
const applicationClient = injector.get(ApplicationClient);
const env = injector.get(Environment);

export const bankerPageRouteId = "underwritex";
export const consumerPageRouteId = "consumer";

/**
 * Loads data from the backend when a navigation to the {@link BankerPage} occurs.
 * @param params
 * @returns
 */
export async function underwritePageLoader(
  params: Params<string>,
  isConsumerRoute: boolean
): Promise<UnderwriteData> {
  const thirdPartyKey =
    undefined !== params.thirdPartyKey
      ? Result.Success(params.thirdPartyKey)
      : Result.Error<string>(
          "Third party key not present in browser route parameters."
        );

  async function getSolution(): Promise<Solution | undefined> {
    const solution = isConsumerRoute
      ? new SimpleSolutionClient(env.lipBaseUrl, env.apiKey).getSolution({
          thirdPartyKey: thirdPartyKey.value,
        })
      : solutionClient.getSolution({ thirdPartyKey: thirdPartyKey.value });
    const result = thirdPartyKey.isSuccess
      ? await solution
      : thirdPartyKey.castError<Solution>();
    if (!result.isSuccess) {
      const message = `Failed to load application with third party key '${thirdPartyKey}'.`;
      await notifications.publishError(message, result.error);
      return undefined;
    }
    return Object.freeze(result.value);
  }

  async function getSalesSettings(): Promise<SalesSettings | undefined> {
    const settings = isConsumerRoute
      ? new SimpleSettingsClient(env.lipBaseUrl, env.apiKey).getSettings(
          SettingsKey.Sales
        )
      : settingsClient.getSettings(SettingsKey.Sales);
    const result = await settings;
    if (!result.isSuccess) {
      await notifications.publishError(
        "Failed to load sales settings.",
        result.error
      );
      return undefined;
    }
    return Object.freeze(result.value as SalesSettings);
  }

  async function getRefis(): Promise<RefinanceOptions | undefined> {
    const refis = isConsumerRoute
      ? new SimpleApplicationClient(
          env.lipBaseUrl,
          undefined,
          env.apiKey
        ).getRefis({ thirdPartyKey: thirdPartyKey.value })
      : applicationClient.getRefis({ thirdPartyKey: thirdPartyKey.value });

    const result = thirdPartyKey.isSuccess
      ? await refis
      : thirdPartyKey.castError<RefinanceOptions>();
    if (!result.isSuccess) {
      await notifications.publishError(
        "Failed to get refinance options.",
        result.error
      );
      return undefined;
    }
    return Object.freeze(result.value);
  }

  const [solution, salesSettings, refis] = await Promise.all([
    getSolution(),
    getSalesSettings(),
    getRefis(),
  ]);

  return { solution, salesSettings, refis };
}

export const useUnderwritePageLoaderData = (pageRouteId?: string) => {
  const matches = useMatches();
  const currentRoute = matches[matches.length - 1];
  const underwriteData = useRouteLoaderData(
    pageRouteId || currentRoute.id
  ) as UnderwriteData;

  return { underwriteData };
};
