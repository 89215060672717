import { useNavigate } from "react-router";
import { RefiInfo, RefiTableDisplaySettings } from "./RefiTable";
import Pill from "../Pills/Pill";
import {
  formatDollars,
  formatPercent,
} from "@uix/common/src/modules/numberModule";
import { SalesSettings } from "@uix/common/src/domain/messages/sales-settings";
import { salesFromRefi } from "@uix/common/src/modules/solutionModule";
import { ChangeEvent, useContext, useEffect, useState } from "react";
import { SelectionContext } from "../../pages/BankerPage/SelectionContext";
import Checkbox from "../Checkbox/Checkbox";

interface RefiRowProps {
  info: RefiInfo;
  salesSettings: SalesSettings | undefined;
  selectable?: boolean;
  inner: number;
  // eslint-disable-next-line
  conversionRate: (index: number) => number;
  displaySettings?: RefiTableDisplaySettings;
}

export const RefiRow: React.FC<RefiRowProps> = ({
  info,
  salesSettings,
  selectable,
  inner,
  conversionRate,
  displaySettings,
}) => {
  const navigate = useNavigate();
  const { selected, setSelected } = useContext(SelectionContext);
  const [selectedRefi, setSelectedRefi] = useState(info);

  // Dev defined. Merge the provided display settings with the default display settings

  useEffect(() => {
    setSelectedRefi(info);
  }, [info]);

  const refisWithDifferentTerms =
    selected?.liabilities
      .filter(
        (x) =>
          x.refinanceProduct?.name === info.refi.refinanceProduct?.name &&
          x.refinanceProductSheet?.adjusted.loanAmount ===
            info.refi.refinanceProductSheet?.adjusted.loanAmount &&
          x.refinanceProductSheet?.allowableLoanTerms != undefined &&
          x.refinanceProductSheet?.allowableLoanTerms.length > 1
      )
      .map((refi) => new RefiInfo(selected, refi, selected?.requestedLoan)) ??
    [];

  const onChange = (event: ChangeEvent<HTMLSelectElement>) => {
    const newRefi = refisWithDifferentTerms.find(
      (x) => x.refi.referenceId === event.target.value
    );

    if (newRefi === undefined) return;

    if (selectedRefi.isSelected) {
      selected?.toggle(newRefi.refi, setSelected);
    } else {
      setSelectedRefi(newRefi);
    }
  };

  return (
    <tr className="border-b-light-gray1 border-y-1">
      <td className="md:py-4 py-2">
        <div>
          <button
            onClick={() =>
              navigate(`open/compare/${selectedRefi.refi.referenceId}`)
            }
            className="btn-sm bg-inherit border-transparent md:body1 body1-m text-cc-skyblue hover:underline hover:bg-inherit hover:border-transparent p-0 active:outline-none focus:outline-none"
          >
            {selectedRefi.refi.refinanceProduct?.name + " >"}
          </button>
        </div>
        <div className="mt-2">
          {selectedRefi.isRecommended && displaySettings.showRecommendedTag && (
            <Pill refi={selectedRefi.refi} type="recommended" />
          )}
          {!selectedRefi.isRecommended &&
            selectedRefi.isUpsell &&
            displaySettings.showUpsellTag && (
              <Pill refi={selectedRefi.refi} type="upsell" />
            )}
          {!selectedRefi.isRecommended &&
            selectedRefi.isCrossSell &&
            displaySettings.showCrossSellTag && (
              <Pill refi={selectedRefi.refi} type="cross-sell" />
            )}
        </div>
      </td>
      <td>
        {selectedRefi.refi.refinanceLiability?.startingBalance !== undefined &&
          formatDollars(selectedRefi.refi.refinanceLiability?.startingBalance)}
      </td>
      {displaySettings.showInterestRate && (
        <td>
          {selectedRefi.refi.refinanceProductSheet?.adjustedInterestRate}
          &nbsp;(
          {selectedRefi.refi.refinanceProductSheet?.primeRateModifies
            ? "Adjustable"
            : "Fixed"}
          )
        </td>
      )}
      <td>
        {refisWithDifferentTerms === null ||
        refisWithDifferentTerms.length === 0 ? (
          selectedRefi.refi.refinanceLiability.debtInstrument?.phases[0]?.term
        ) : (
          <select
            onChange={onChange}
            value={selectedRefi.refi.referenceId}
            className="select select-bordered select-sm w-full max-w-xs bg-white min-w-32"
          >
            {refisWithDifferentTerms?.map((refi, k) => (
              <option key={k} value={refi.refi.referenceId}>
                {refi.refi.refinanceLiability.debtInstrument?.phases[0].term}
                &nbsp;Months
              </option>
            ))}
          </select>
        )}
      </td>
      {displaySettings.showEstConversionRate && (
        <td>{formatPercent(conversionRate(inner), 0)}</td>
      )}
      {displaySettings.showEstCommission && (
        <td>
          {salesSettings?.commissionRate
            ? formatDollars(
                (salesFromRefi(selectedRefi.refi) *
                  salesSettings?.commissionRate) /
                  100
              )
            : "(unknown)"}
        </td>
      )}
      {displaySettings.showEstMonthlyPayment && (
        <td>
          {selectedRefi.refi.refinanceLiability?.debtInstrument?.phases.at(0)
            .payment
            ? formatDollars(
                selectedRefi.refi.refinanceLiability?.debtInstrument?.phases.at(
                  0
                ).payment
              )
            : "(unknown)"}
        </td>
      )}
      {selectable && (
        <td>
          <Checkbox
            id={inner.toString()}
            checked={selectedRefi.isSelected}
            onChange={() => selected?.toggle(selectedRefi.refi, setSelected)}
          />
        </td>
      )}
    </tr>
  );
};

export default RefiRow;
