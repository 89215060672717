import { useState } from "react";
import { PrimaryButton, PrimaryCard } from "@ucl/library";
import { UserService } from "@uix/common/src/services/userService";
import { injector } from "../../injector";

const userService = injector.get(UserService);

const ClaimsTest: React.FC = () => {
  const [claims, setClaims] = useState({});

  async function getClaims(): Promise<void> {
    const newClaims = await userService.userClaims();
    setClaims(newClaims);
  }

  const json = JSON.stringify(claims, null, 4);
  return (
    <PrimaryCard headerContent="User Claims">
      <p>Click the button to get the current user&apos;s claims.</p>
      <PrimaryButton onClick={getClaims} content="Get Claims"></PrimaryButton>
      <pre>{json}</pre>
    </PrimaryCard>
  );
};

export default ClaimsTest;
