import { Liability } from "@uix/common/src/domain/messages/liability";
import {
  formatDollars,
  formatPercent,
} from "@uix/common/src/modules/numberModule";
import { loanGroupDisplayName } from "@uix/common/src/modules/loanGroupingModule";

export interface ConsolidationInfoProps {
  liability: Liability;
}

export const ConsolidationInfo: React.FC<ConsolidationInfoProps> = (props) => {
  const { liability } = props;
  return (
    <div className="separating-container">
      <p>
        {liability.debtInstrument?.creditorName}&nbsp;
        {loanGroupDisplayName(liability.debtInstrument?.loanGroup)}
      </p>
      <p>
        {formatPercent(
          liability.debtInstrument?.phases[0]?.nominalInterest ?? 0
        )}
        &nbsp;APR
      </p>
      <p>{formatDollars(liability.currentBalance ?? 0)}</p>
    </div>
  );
};

export default ConsolidationInfo;
