import { PrimaryCard, SecondaryCard } from "@ucl/library";
import PrimaryButtonAsync from "@uix/common/src/components/Buttons/PrimaryButtonAsync";
import SecondaryButtonAsync from "@uix/common/src/components/Buttons/SecondaryButtonAsync";
import { useState } from "react";
import { injector } from "../../injector";
import { SolutionClient } from "@uix/common/src/services/solutionClient";
import { Solution } from "@uix/common/src/domain/messages/solution";
import { NotificationsService } from "@uix/common/src/services/notificationService";

const solutionClient = injector.get(SolutionClient);
const notificationsService = injector.get(NotificationsService);

interface State {
  solutionId: string;
  solutionJson: string;
  isPreApproved?: boolean;
}

function initialState(): State {
  return {
    solutionId: "",
    solutionJson: "",
  };
}

export const SolutionTest: React.FC = () => {
  const [state, setState] = useState(initialState());

  function solutionIdChange(event: React.FormEvent<HTMLInputElement>): void {
    const solutionId = event.currentTarget.value;
    setState({ ...state, solutionId });
  }

  function solutionJsonChange(
    event: React.FormEvent<HTMLTextAreaElement>
  ): void {
    const solutionJson = event.currentTarget.value;
    setState({ ...state, solutionJson });
  }

  async function load(): Promise<void> {
    const solutionId = state.solutionId;
    const result = await solutionClient.getSolution({ solutionId });
    if (!result.isSuccess) {
      const message = `Failed to load solution: ${result.error}`;
      await notificationsService.publishError(message, result.error);
      return;
    }
    const solutionJson = JSON.stringify(result.value, null, 2);
    const isPreApproved = undefined;
    setState({ ...state, solutionJson, isPreApproved });
  }

  async function save(): Promise<void> {
    const solution = JSON.parse(state.solutionJson) as Solution;
    const result = await solutionClient.putSolution(solution);
    if (!result.isSuccess) {
      const message = `Failed to save solution: ${result.error}`;
      await notificationsService.publishError(message, result.error);
      return;
    }
  }

  async function isPreApproved(): Promise<void> {
    const solution = JSON.parse(state.solutionJson) as Solution;
    const result = await solutionClient.preApproval(solution);
    if (!result.isSuccess) {
      const message = `Failed to get pre-approval status: ${result.error}`;
      await notificationsService.publishError(message, result.error);
      return;
    }
    const isPreApproved = result.value;
    setState({ ...state, isPreApproved });
  }

  return (
    <PrimaryCard headerContent="Solution Test">
      <p>Enter a solution ID below to see it displayed below.</p>
      <label>Solution ID:</label>
      <div className="flexit">
        <input type="text" onChange={solutionIdChange} />
        <SecondaryButtonAsync onClick={load} content="Load" />
        <PrimaryButtonAsync onClick={save} content="Save" />
        <SecondaryButtonAsync
          onClick={isPreApproved}
          content="Is Pre-approved"
        />
      </div>
      <div className="flexit">
        <label>Pre-Approval Status:&nbsp;</label>
        <span>
          {state.isPreApproved === undefined
            ? "unknown"
            : state.isPreApproved
            ? "true"
            : "false"}
        </span>
      </div>
      <SecondaryCard headerContent="Solution JSON">
        <textarea
          cols={100}
          rows={30}
          value={state.solutionJson}
          onChange={solutionJsonChange}
        ></textarea>
      </SecondaryCard>
    </PrimaryCard>
  );
};

export default SolutionTest;
