import { RefinanceOptions } from "@uix/common/src/domain/messages/refinance-options";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faGem } from "@fortawesome/free-solid-svg-icons";
import Dollar from "@uix/common/src/components/Dollar";
import { PrimaryButton } from "@ucl/library";
import { Dispatch, SetStateAction } from "react";
import { RefinanceOption } from "@uix/common/src/domain/messages/refinance-option";
import classNames from "classnames";
import "./styles.scss";
import {
  RefinanceDecisionOption,
  useDecisionComponent,
} from "./useDecisionComponent";
import { CarouselComponent } from "@syncfusion/ej2-react-navigations";

export interface DecisionComponentProps {
  refis: Readonly<RefinanceOptions>;
  setSelection?: Dispatch<SetStateAction<RefinanceOption>>;
}
const DecisionComponent: React.FC<DecisionComponentProps> = (props) => {
  const {
    handleSelection,
    requestedLoanDisplayName,
    selected,
    requestedLoanOptions,
  } = useDecisionComponent(props);

  return (
    <div>
      <div className="decision-container container px-4 py-4 mx-auto">
        <h1 className="decision-header">
          <FontAwesomeIcon size="lg" icon={faGem} />
          Congratulations, You&apos;re Pre-Qualified for Multiple
          {` ${requestedLoanDisplayName} `}
          Options!
        </h1>
        <p className="decision-desc">
          You&apos;re pre-qualified for a variety of
          {` ${requestedLoanDisplayName.toLowerCase()} `}
          loan options, each suited to your financial needs. Take a moment to
          explore them and choose the one that best fits your plans for your
          next {` ${requestedLoanDisplayName.toLowerCase()} `}.
        </p>
        <div className="decision-summary-container">
          {requestedLoanOptions?.length > 0 && (
            <CarouselComponent
              autoPlay={false}
              loop={false}
              enableTouchSwipe={false}
              showIndicators={true}
              buttonsVisibility="Visible"
              partialVisible={false}
              dataSource={requestedLoanOptions}
              itemTemplate={(refiGrouped: RefinanceDecisionOption[]) => {
                return (
                  <ul className="decision-summary">
                    {Object.values(refiGrouped).map((refi) => (
                      <li
                        key={refi.refinanceProduct.id.toString()}
                        className={classNames("decision-summary-item", {
                          "decision-summary-item-selected":
                            selected?.refinanceProduct.id ===
                            refi.refinanceProduct.id,
                        })}
                      >
                        {refi.isRecommended && (
                          <div className="decision-summary-item-product-badge">
                            <div>Your Best Fit</div>
                          </div>
                        )}
                        <div className="decision-summary-item-product-name">
                          <div>{refi.refinanceProduct.name}</div>
                        </div>
                        <div className="decision-summary-item-product-details">
                          <div>
                            <div>Loan Amount</div>
                            <div>
                              {refi.refinanceLiability.startingBalance && (
                                <span>
                                  <Dollar
                                    value={
                                      refi.refinanceLiability.startingBalance ??
                                      0
                                    }
                                  />
                                </span>
                              )}
                            </div>
                          </div>
                          <div>
                            <div>Term</div>
                            <div>
                              {refi.refinanceLiability.debtInstrument.phases[0]
                                .term && (
                                <span>
                                  {
                                    refi.refinanceLiability.debtInstrument
                                      .phases[0].term
                                  }{" "}
                                  months
                                </span>
                              )}
                            </div>
                          </div>
                          <div>
                            <div>APR</div>
                            <div>
                              {refi.refinanceLiability.debtInstrument.phases[0]
                                .nominalInterest && (
                                <span>
                                  {
                                    refi.refinanceLiability.debtInstrument
                                      .phases[0].nominalInterest
                                  }
                                  %
                                </span>
                              )}
                            </div>
                          </div>
                          <div>
                            <div>Monthly Payment</div>
                            <div>
                              {refi.refinanceLiability.debtInstrument.phases[0]
                                .payment && (
                                <span>
                                  <Dollar
                                    value={
                                      refi.refinanceLiability.debtInstrument
                                        .phases[0].payment ?? 0
                                    }
                                  />
                                </span>
                              )}
                            </div>
                          </div>
                        </div>
                        <div className="decision-summary-item-product-selection">
                          {selected?.refinanceProduct.id !==
                            refi.refinanceProduct.id && (
                            <PrimaryButton
                              content="Select"
                              onClick={() => handleSelection(refi)}
                            />
                          )}
                        </div>
                      </li>
                    ))}
                  </ul>
                );
              }}
            ></CarouselComponent>
          )}
        </div>
      </div>
    </div>
  );
};

export default DecisionComponent;
