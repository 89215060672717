import { RefinanceOption } from "@uix/common/src/domain/messages/refinance-option";
import { ChangeEvent, useEffect, useState } from "react";
import { RefinanceOptions } from "@uix/common/src/domain/messages/refinance-options";
import { Refinance } from "@uix/common/src/domain/messages/refinance";
import { refinanceFrom } from "@uix/common/src/modules/solutionModule";
import { liabilityLiteHash } from "@uix/common/src/modules/liabilityModule";

const addOptionType = (
  op: RefinanceOption,
  type: "recommended" | "upsell" | "cross-sell"
) => ({
  ...op,
  _uitype: type,
});

export const useProductInfo = ({
  refis,
  referenceId,
  isSecondItem,
}: {
  referenceId?: string;
  refis?: RefinanceOptions;
  isSecondItem?: boolean;
}) => {
  const [options, setOptions] = useState<
    (RefinanceOption & { _uitype: "recommended" | "upsell" | "cross-sell" })[]
  >([]);
  const [refi, setRefi] = useState<Refinance>();
  const [selectedOptionReferenceId, setSelectedOptionReferenceId] = useState(
    !isSecondItem ? referenceId : undefined
  );

  const recommendedOption =
    refis?.requestedLoan?.options
      .filter((op) => op.refinanceProduct !== undefined)
      .map((op) => addOptionType(op, "recommended")) || [];
  const upsellOptions =
    refis?.requestedLoan?.upsellOptions
      .filter((op) => op.refinanceProduct !== undefined)
      .map((op) => addOptionType(op, "upsell")) || [];
  const crossSellOptions =
    refis?.liabilities
      .reduce((prev, next) => {
        return prev.concat(next.options);
      }, [] as RefinanceOption[])
      .filter((op) => op.refinanceProduct !== undefined)
      .map((op) => addOptionType(op, "cross-sell")) || [];
  const allOptions = [
    ...recommendedOption,
    ...upsellOptions,
    ...crossSellOptions,
  ];
  const original = refis?.requestedLoan?.originalLiabilities;

  if (!original) {
    throw new Error("Missing Original Liabilities");
  }

  const refinances =
    original && allOptions
      ? (allOptions.map((e) => refinanceFrom(original, e)) as (Refinance & {
          referenceId: string;
        })[])
      : [];

  const getOptions = () => {
    const selectedOption = allOptions.find(
      (op) => op.referenceId == referenceId
    );
    if (
      selectedOption?._uitype === "recommended" ||
      selectedOption?._uitype === "upsell"
    ) {
      return [...recommendedOption, ...upsellOptions];
    } else {
      return crossSellOptions.filter(
        (op) =>
          liabilityLiteHash(op.refinanceLiability) ===
          liabilityLiteHash(selectedOption?.refinanceLiability)
      );
    }
  };

  const handleChange = (event: ChangeEvent<HTMLSelectElement>) => {
    const value = event.target.value;
    setSelectedOptionReferenceId(value);
  };

  useEffect(() => {
    setOptions(getOptions());
  }, []);

  useEffect(() => {
    const firstOption = options[0];

    setRefi(
      refinances.find((refi) =>
        selectedOptionReferenceId
          ? refi.referenceId === selectedOptionReferenceId
          : refi.referenceId == firstOption?.referenceId
      )
    );
  }, [selectedOptionReferenceId, options]);

  return {
    options,
    handleChange,
    refi,
    setRefi,
    refinances,

    selectedOptionReferenceId,
  };
};
