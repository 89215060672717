import { SalesSettings } from "@uix/common/src/domain/messages/sales-settings";
import EligibleProductsCard from "./EligibleProductsCard/EligibleProductsCard";
import { RefinanceOptions } from "@uix/common/src/domain/messages/refinance-options";
import ApprovalSolution from "./ApprovalSolution/ApprovalSolution";
import { Solution } from "@uix/common/src/domain/messages/solution";

export interface DecisionProps {
  /** Indicates if the applicant is approved for the loan they requested. */
  refis: RefinanceOptions | undefined;
  salesSettings: SalesSettings | undefined;
  solution: Solution | undefined;
}

export const Decision: React.FC<DecisionProps> = ({
  salesSettings,
  solution,
}) => {
  const isApproved = solution.canApplicationBeApproved;

  return (
    <>
      <EligibleProductsCard salesSettings={salesSettings} />
      {!isApproved && <ApprovalSolution solution={solution} />}
    </>
  );
};

export default Decision;
