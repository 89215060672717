import { Result } from "../domain/result";
import { Injectable } from "injection-js";
import { Environment } from "../environment";
import { BaseClient } from "./baseClient";
import { AuthService } from "./authService";
import { UserClaimsResult } from "../domain/messages/user-claims-result";

/**
 * Client for interacting with the Security Host.
 */
@Injectable()
export class SecurityHostClient extends BaseClient {
    constructor(env: Environment, authService: AuthService) {
        super(env.loginBaseUrl, authService);
    }

    public async updateCurrentUserClaims(): Promise<Result<UserClaimsResult>> {
        const result = await this.post({}, "/api/User/UpdateCurrentUserClaims");
        return result as Result<UserClaimsResult>;
    }
}