import { AuthService } from "./authService";
import { Injectable } from "injection-js";
import { SecurityHostClient } from "./securityHostClient";
import { Notification, NotificationsService } from "./notificationService";
import { HttpError } from "./baseClient";
import { NotificationLevel } from "./notificationLevel";
import { Result } from "../domain/result";

/**
 * Represents the different roles in the system (don't mind the name, it's historical).
 * These values must match those in the "Permissions" class in the backend.
 */
export enum Permissions {
  SYSTEM_ADMIN = "systemadmin",
  ADMINISTRATION_CLIENT_ADMIN = "administration.clientadmin",
  ANYONE = "anyone",
}

/**
 * The claims associated with a user.
 */
export interface UserClaims {
  // These claims come from Entra ID.
  email: string;
  name: string;
  preferred_username: string;
  sub: string;

  // The remaining claims come from the security host.
  /** Values from the {@link Permissions} enum. */
  roles: string | string[];
  tenant_id: string;
  tenant_name: string;
  los_type: string;
  time_zone_id: string;
  time_zone_utc_offset_mins: string;
}

@Injectable()
export class UserService {
  private readonly _userClaims: Promise<UserClaims>;

  constructor(
    authService: AuthService,
    securityClient: SecurityHostClient,
    private readonly _notificationsService: NotificationsService) {
    this._userClaims = this.getClaims(authService, securityClient);
  }

  private static readonly claimTypes = Object.freeze({
    role: "http://schemas.microsoft.com/ws/2008/06/identity/claims/role",
    tenant_id: "http://schemas.microsoft.com/identity/claims/tenantid",
    tenant_name: "tenant_name",
    los_type: "los_type",
    time_zone_id: "time_zone_id",
    time_zone_utc_offset_mins: "time_zone_utc_offset_mins",
  });

  private async getClaims(
    authService: AuthService, securityClient: SecurityHostClient
  ): Promise<UserClaims> {
    const baseClaims = await authService.claims();
    let result = await securityClient.updateCurrentUserClaims();
    if (!result.isSuccess) {
      const context = result.error instanceof HttpError ? result.error.body : undefined;
      const notification = new Notification(
        NotificationLevel.Error, `Failed to get user information: ${result.error}`, context
      );
      await this._notificationsService.publish(notification);
      result = new Result({value: { claims: [] }});
    }
    var extraClaimsResult = result.value;
    // The role claim is the only one allowed to have multiple values.
    const roles = extraClaimsResult.claims
      .filter(e => e.Type === UserService.claimTypes.role)
      .map(e => e.Value);
    const extraClaims = extraClaimsResult.claims
      .reduce((prev, curr) => {
        prev[curr.Type] = curr.Value;
        return prev;
      }, {} as {[key: string]: string});
    return Object.freeze({
      email: baseClaims?.email,
      name: baseClaims?.name,
      preferred_username: baseClaims?.preferred_username,
      sub: baseClaims?.sub,
      roles,
      tenant_id: extraClaims[UserService.claimTypes.tenant_id],
      tenant_name: extraClaims[UserService.claimTypes.tenant_name],
      los_type: extraClaims[UserService.claimTypes.los_type],
      time_zone_id: extraClaims[UserService.claimTypes.time_zone_id],
      time_zone_utc_offset_mins: extraClaims[UserService.claimTypes.time_zone_utc_offset_mins],
    });
  }

  /** Returns the claims for the logged in user. */
  public userClaims(): Promise<UserClaims> {
    return this._userClaims;
  }
}
