import { addressString } from "@uix/common/src/modules/addressModule";
import { creditScoresAverage } from "@uix/common/src/modules/creditScoresModule";
import {
  formatNumber,
  formatPercent,
} from "@uix/common/src/modules/numberModule";
import { useContext } from "react";
import { SelectionContext } from "../../../pages/BankerPage/SelectionContext";

export const CustomerProfile: React.FC = () => {
  const { selected } = useContext(SelectionContext);
  const customer = selected?.solution?.primaryApplicant;

  const fullName = `${customer?.firstName} ${customer?.lastName}`;
  const address = addressString(customer?.presentAddress);
  const averageCreditScore = creditScoresAverage(customer?.creditScores);
  const income =
    (customer?.financialInfo?.yearlyGrossIncome?.totalIncome ?? 0) / 12;
  const debt =
    customer?.liabilities.reduce(
      (prev, curr) => prev + (curr.debtInstrument?.phases[0]?.payment ?? 0),
      0
    ) ?? 0;
  const dti = income !== 0 ? (debt / income) * 100 : 100;

  function padFunction(ssn: string) {
    const string = String(ssn);
    const sliced = string.slice(-4);
    const mask = "***-**-" + sliced;
    return mask;
  }

  return (
    <>
      <div className="px-4 py-3 mt-3">
        <h5>Name</h5>
        <span className="body2 text-dark-gray3">{fullName}</span>
      </div>

      <div className="px-4 py-3">
        <h5>Address</h5>
        <span className="body2 text-dark-gray3">
          {address ? address : "N/A"}
        </span>
      </div>

      <div className="px-4 py-3">
        <h5>Date of Birth</h5>
        <span className="body2 text-dark-gray3">N/A</span>
      </div>

      <div className="px-4 py-3">
        <h5>Phone</h5>
        <span className="body2 text-dark-gray3">
          {customer?.phoneNumber ? customer?.phoneNumber : "N/A"}
        </span>
      </div>

      <div className="px-4 py-3">
        <h5>Email</h5>
        <span className="body2 text-dark-gray3">
          {customer?.email ? customer?.email : "N/A"}
        </span>
      </div>

      <div className="px-4 py-3">
        <h5>SSN</h5>
        <span className="body2 text-dark-gray3">
          {customer?.ssn ? padFunction(customer?.ssn) : "N/A"}
        </span>
      </div>

      <div className="px-4 py-3">
        <h5>Credit Score</h5>
        <span className="body2 text-dark-gray3">
          {averageCreditScore ? formatNumber(averageCreditScore, 0) : "N/A"}
        </span>
      </div>

      <div className="px-4 py-3">
        <h5>DTI</h5>
        <span className="body2 text-dark-gray3">
          {dti ? formatPercent(dti) : "N/A"}
        </span>
      </div>
    </>
  );
};

export default CustomerProfile;
