import { Refinance } from "@uix/common/src/domain/messages/refinance";
import { RefinanceOptions } from "@uix/common/src/domain/messages/refinance-options";
import { Solution } from "@uix/common/src/domain/messages/solution";
import { liabilityHashes as liabilitiesHash } from "@uix/common/src/modules/liabilityModule";
import {
  byDescendingRank,
  refinanceFrom,
  refinanceHash,
} from "@uix/common/src/modules/solutionModule";
import { createContext } from "react";

// eslint sure does suck. Can't even understand function types...
// eslint-disable-next-line no-unused-vars
type SetSelected = ((solution: SelectedSolution) => void) | undefined;

export interface ISelectionContext {
  /** The {@link Solution} containing the current loan selections. */
  selected: SelectedSolution | undefined;
  /** Updates the solution. */
  setSelected: SetSelected | undefined;
}

interface SelectedSolutionArgs {
  solution: Solution;
  requestedLoan: readonly Refinance[];
  liabilities: readonly Refinance[];
  recommended: readonly Refinance[];
  upsellOptions: readonly Refinance[];
  recommendedHashes: Readonly<Set<number>>;
  topRankOptionForEachLiability: readonly Refinance[];
}

/** A {@link Solution} together with data structures which enable efficient queries. */
export class SelectedSolution {
  /** The {@link Solution} that the user has built. */
  public readonly solution: Solution;

  /** The set of {@link Refinance} hash codes inside `solution`. */
  private readonly selected: Readonly<Set<number>>;

  /**
   * The list of {@link Refinance} options available for the requested loan.
   */
  public readonly requestedLoan: readonly Refinance[];

  /**
   * The lists of {@link Refinance} options available for the liabilities.
   */
  public readonly liabilities: readonly Refinance[];

  /**
   * The set of refinance options which were produced by the engine.
   */
  public readonly recommended: readonly Refinance[];

  /**
   * The set of hash codes in the `recommended` array.
   */
  public readonly recommendedHashes: Readonly<Set<number>>;

  public readonly upsellOptions: readonly Refinance[];

  public readonly topRankOptionForEachLiability: readonly Refinance[];

  constructor({
    solution,
    requestedLoan,
    liabilities,
    recommended,
    recommendedHashes,
    upsellOptions,
    topRankOptionForEachLiability,
  }: SelectedSolutionArgs) {
    this.solution = solution;
    this.requestedLoan = requestedLoan;
    this.liabilities = liabilities;
    this.recommended = recommended;
    this.upsellOptions = upsellOptions;
    this.recommendedHashes = recommendedHashes;
    this.topRankOptionForEachLiability = topRankOptionForEachLiability;
    function* refinanceHashes() {
      for (const refi of solution.items) {
        if (refi === undefined) continue;
        yield refinanceHash(refi);
      }
    }
    this.selected = new Set(refinanceHashes());
  }

  /**
   * Returns true if the given {@link Refinance} has been selected.
   * @param refi
   * @returns
   */
  public isSelected(refi: Refinance): boolean {
    return this.selected.has(refinanceHash(refi));
  }

  /**
   * Returns true if the given {@link Refinance} is part of the recommended solution.
   * @param refi
   * @returns
   */
  public isRecommended(refi: Refinance): boolean {
    return this.recommendedHashes.has(refinanceHash(refi));
  }

  /**
   * Toggles the selection state of the given {@link Refinance}, then calls
   * `setSelected` with a new object containing the modified {@link Solution}.
   */
  public toggle(refi: Refinance, setSelected: SetSelected): void {
    const { solution } = this;
    const hash = liabilitiesHash(refi.originalLiabilities);
    const index = solution.items.findIndex(
      (e) => hash === liabilitiesHash(e.originalLiabilities)
    );
    if (index < 0) {
      solution.items.push(refi);
    } else {
      const [previous] = solution.items.splice(index, 1);
      if (refinanceHash(refi) !== refinanceHash(previous)) {
        solution.items.push(refi);
      }
    }
    if (undefined === setSelected) return;

    setSelected(
      new SelectedSolution({
        ...this,
        solution,
      })
    );
  }

  /**
   * Toggles the selection state of the given {@link Refinance}, then calls
   * `setSelected` with a new object containing the modified {@link Solution}.
   */
  public setSelectedFromHash(
    selectedHash: number,
    setSelected: SetSelected
  ): void {
    const { solution, upsellOptions, recommended } = this;
    const refi = [...upsellOptions, ...recommended].find(
      (op) => refinanceHash(op) == selectedHash
    );
    const hash = liabilitiesHash(refi.originalLiabilities);
    const index = solution.items
      .filter((it) => !!it)
      .findIndex((e) => hash === liabilitiesHash(e?.originalLiabilities));

    if (index < 0) {
      solution.items.push(refi);
    }

    if (undefined === setSelected) return;

    setSelected(
      new SelectedSolution({
        ...this,
        solution,
      })
    );
  }

  public static init(
    solution: Solution | undefined,
    refis: RefinanceOptions | undefined
  ): SelectedSolution | undefined {
    if (!solution || !refis) return undefined;

    const recommended =
      refis.requestedLoan?.options.map((op) =>
        refinanceFrom(refis.requestedLoan?.originalLiabilities ?? [], op)
      ) || [];
    const recommendedHashes = new Set(recommended?.map(refinanceHash));
    // Clone all entries in solution, except for items.
    solution = structuredClone({ ...solution, items: [] });
    delete solution.solutionId;
    const requestedLoan =
      refis.requestedLoan?.options
        .map((option) =>
          refinanceFrom(refis.requestedLoan?.originalLiabilities ?? [], option)
        )
        .sort(byDescendingRank) ?? [];

    const upsells = (refis.requestedLoan?.upsellOptions || [])
      .map((upsell) =>
        refinanceFrom(refis.requestedLoan?.originalLiabilities ?? [], upsell)
      )
      .sort(byDescendingRank);
    const liabilities = refis.liabilities
      .flatMap((group) =>
        group.options
          .filter((option) => option.refinanceProduct !== undefined)
          .map((option) => refinanceFrom(group.originalLiabilities, option))
      )
      .sort(byDescendingRank);

    const topRankOptionForEachLiability: Refinance[] = [];

    refis.liabilities.forEach((liability) => {
      const sortedOptions = liability.options
        .map((option) => refinanceFrom(liability.originalLiabilities, option))
        .sort(byDescendingRank);

      topRankOptionForEachLiability.push(sortedOptions[0]);
    });

    return new SelectedSolution({
      solution,
      requestedLoan: Object.freeze(requestedLoan),
      liabilities: Object.freeze(liabilities),
      upsellOptions: upsells,
      recommended,
      recommendedHashes,
      topRankOptionForEachLiability,
    });
  }
}

/**
 * Allows getting and setting the solution which the user has selected.
 */
export const SelectionContext = createContext<ISelectionContext>({
  selected: undefined,
  setSelected: undefined,
});
