import RefiTable, { RefiTableDisplaySettings } from "../RefiTable/RefiTable";
import { SalesSettings } from "@uix/common/src/domain/messages/sales-settings";
import { useContext } from "react";
import { SelectionContext } from "../../pages/BankerPage/SelectionContext";

export interface SolutionCardProps {
  title?: string;
  salesSettings: SalesSettings | undefined;
  displaySettings?: RefiTableDisplaySettings;
}

export const SolutionCard: React.FC<SolutionCardProps> = ({
  title,
  salesSettings,
  displaySettings,
}) => {
  const { selected } = useContext(SelectionContext);

  return (
    <div className="card w-full bg-white shadow-lg rounded-md overflow-x-auto">
      <div className="card-body">
        <h3>{title}</h3>

        <RefiTable
          refis={[
            ...(selected?.recommended || []),
            ...(selected?.topRankOptionForEachLiability || []),
          ]}
          salesSettings={salesSettings}
          selectable={true}
          displaySettings={displaySettings}
        />
      </div>
    </div>
  );
};

export default SolutionCard;
