import { AmortizationPhase } from "@uix/common/src/domain/messages/amortization-phase";
import { formatPercent } from "@uix/common/src/modules/numberModule";

export interface InterestRateFormatterProps {
  phases: AmortizationPhase[] | undefined;
}

const InterestRateFormatter: React.FC<InterestRateFormatterProps> = ({
  phases,
}) => {
  if (phases === undefined) return <></>;

  const isAdjustable = phases.length > 1;
  const firstPhase = phases[0];

  let formattedInterestRate;

  if (isAdjustable) {
    const secondPhase = phases[1];
    formattedInterestRate = (
      <>
        <span className="block">
          Fixed: {formatPercent(firstPhase.nominalInterest)} for the first&nbsp;
          {firstPhase.term} months.
        </span>
        <span className="block">
          Variable: Prime Rate + {formatPercent(secondPhase.nominalInterest)}
          &nbsp;adjusting annually.
        </span>
      </>
    );
  } else {
    formattedInterestRate = (
      <span>Fixed: {formatPercent(firstPhase.nominalInterest)}</span>
    );
  }

  return (
    <div className="flyout-drawer-item-row">
      <h5 className="flyout-drawer-item-title">Interest Rate</h5>
      <span className="flyout-drawer-item-value">{formattedInterestRate}</span>
    </div>
  );
};

export default InterestRateFormatter;
