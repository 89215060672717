import React from "react";

export interface Button extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  label: string;
}

export const Button: React.FC<Button> = ({ onClick, label }) => {
  const buttonClass = `btn btn-primary bg-cc-skyblue border-cc-skyblue hover:border-cc-skyblue hover:bg-cc-skyblue hover:no-underline outline-none`;

  return (
    <button onClick={onClick} className={buttonClass}>
      <span className="text-white body1">{label}</span>
    </button>
  );
};
