import { ReactElement } from "react";
import { Icon } from "@blueprintjs/core";

export interface MetricChangeProps {
  /** The name of the metric. */
  name: string;
  /** The value of the metric before refinancing. */
  before: number;
  /** The value of the metric after refinancing. */
  after: number;
  /** A function which transforms the metric value into an element of the DOM. */
  // eslint-disable-next-line no-unused-vars
  template: (value: number) => ReactElement;
  /** Returns true if the change from `before` to `after` is considered an "improvement". */
  // eslint-disable-next-line no-unused-vars
  isImprovement: (before: number, after: number) => boolean;
}

export const MetricChange: React.FC<MetricChangeProps> = (props) => {
  const { name, before, after, template, isImprovement } = props;
  const improvement = isImprovement(before, after);
  return (
    <div className="metric-change__container flex flex-col items-center">
      <div className="mb-2">
        <label className="metric-change__container-header">{name}</label>
      </div>
      <div className="metric-change__container-value-label">
        {template(before)}
      </div>
      <div className="metric-change__container-description my-3">
        <Icon icon="arrow-down" color={improvement ? "green" : "black"} />
      </div>
      <div className="metric-change__container-value-label">
        {template(after)}
      </div>
    </div>
  );
};

export default MetricChange;
