import { faPaperPlane } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Dollar from "@uix/common/src/components/Dollar";
import { AmortizationKind } from "@uix/common/src/domain/messages/amortization-kind";
import { Solution } from "@uix/common/src/domain/messages/solution";
import { loanGroupDisplayName } from "@uix/common/src/modules/loanGroupingModule";
import React from "react";

export interface SolutionConfirmationProps {
  solution: Solution | undefined;
}

const SolutionConfirmation: React.FC<SolutionConfirmationProps> = (props) => {
  return (
    <div className="solution-confirmation-container">
      <h1 className="solution-confirmation-header">
        <FontAwesomeIcon size="lg" icon={faPaperPlane} />
        Your Application is Submitted!
      </h1>
      <p className="solution-confirmation-desc">
        Thank you for your selections! Best Bank is currently reviewing your
        choices and will be in touch with you shortly to discuss the next steps.
        In the meantime, you can review your selected products below. We
        appreciate your trust in choosing us for your financial needs and look
        forward to assisting you further. Keep an eye out for our follow-up!
      </p>
      <ul className="solution-confirmation-summary">
        {props.solution?.items
          .filter((x) => x.refinanceProduct)
          .map((refi, i) => (
            <li key={i} className="solution-confirmation-summary-item">
              <div className="solution-confirmation-summary-item-product">
                <div>{refi.refinanceProduct?.name}</div>
                <div>
                  {refi.description
                    ? refi.description
                    : loanGroupDisplayName(
                        refi.refinanceLiability.debtInstrument?.loanGroup
                      )}
                </div>
              </div>
              <div>
                <div>Loan Amount</div>
                <div>
                  {refi.refinanceLiability.startingBalance && (
                    <Dollar
                      value={refi.refinanceLiability.startingBalance ?? 0}
                    />
                  )}
                </div>
              </div>
              <div>
                <div>Term</div>
                <div>
                  {refi.refinanceLiability.debtInstrument?.phases?.at(0)
                    ?.term && (
                    <span>
                      {
                        refi.refinanceLiability.debtInstrument?.phases?.at(0)
                          ?.term
                      }
                      months
                    </span>
                  )}
                </div>
              </div>
              <div>
                <div>Est. APR</div>
                <div>
                  {refi.refinanceLiability.debtInstrument?.phases?.at(0)
                    ?.nominalInterest && (
                    <>
                      <span>
                        {
                          refi.refinanceLiability.debtInstrument?.phases?.at(0)
                            ?.nominalInterest
                        }
                        %
                      </span>

                      {refi.refinanceLiability.debtInstrument?.phases?.at(0)
                        ?.kind === AmortizationKind.Structured && (
                        <span className="subtle"> Fixed</span>
                      )}
                    </>
                  )}
                </div>
              </div>
              <div>
                <div>Est. Monthly Payment</div>
                <div>
                  {refi.refinanceLiability.debtInstrument?.phases?.at(0)
                    ?.payment && (
                    <Dollar
                      value={
                        refi.refinanceLiability.debtInstrument?.phases?.at(0)
                          ?.payment ?? 0
                      }
                    />
                  )}
                </div>
              </div>
            </li>
          ))}
      </ul>
    </div>
  );
};

export default SolutionConfirmation;
