import { RefinanceOptions } from "../domain/messages/refinance-options";
import { Result } from "../domain/result";
import { ApplicationQuery } from "./applicationClient";
import { AuthService } from "./authService";
import { BaseClient, IQueryParams } from "./baseClient";

export class SimpleApplicationClient extends BaseClient {
  constructor(lipBaseUrl: string, authService?: AuthService, apiKey?: string) {
    super(BaseClient.uriJoin(lipBaseUrl, "application"), authService, apiKey);
  }

  /**
   * Gets the {@link RefinanceOptions} for the application with the given ID.
   *
   * The return value is, essentially, an array of arrays, where each entry in the outer array is
   * the array of refinancing options for a particular liability. In other words, the original
   * liabilities for each entry of an inner array are the same.
   * @param id
   * @returns
   */
  public async getRefis(
    input: ApplicationQuery
  ): Promise<Result<RefinanceOptions>> {
    const { appId, thirdPartyKey } = input;
    if (undefined === appId && undefined === thirdPartyKey) {
      return Result.Error("appId or thirdPartyKey must be supplied.");
    }
    const query: IQueryParams = {};
    if (undefined !== appId) {
      query.appId = appId;
    }
    if (undefined !== thirdPartyKey) {
      query.thirdPartyKey = thirdPartyKey;
    }
    const result = await this.get("refis", query);
    return result as Result<RefinanceOptions>;
  }
}
