import { UnderwriteData } from "../types/underwrite";
import { Solution } from "@uix/common/src/domain/messages/solution";
import { useState } from "react";
import {
  ISelectionContext,
  SelectedSolution,
} from "./BankerPage/SelectionContext";
import { injector } from "../injector";
import { NotificationLevel } from "@uix/common/src/services/notificationLevel";
import {
  Notification,
  NotificationsService,
} from "@uix/common/src/services/notificationService";
import { SolutionClient } from "@uix/common/src/services/solutionClient";

const solutionClient = injector.get(SolutionClient);
const notifications = injector.get(NotificationsService);

export const useUnderwrite = (data: UnderwriteData) => {
  const [selected, setSelected] = useState(() =>
    SelectedSolution.init(data.solution, data.refis)
  );

  const [submittedSolution, setSubmittedSolution] = useState<Solution>();

  const context: ISelectionContext = {
    selected,
    setSelected,
  };
  const [isDenied, setIsDenied] = useState(false);

  const hasSolutionSelected =
    !!selected?.solution.items && selected?.solution.items?.length > 0;

  async function getPreApproval(): Promise<boolean> {
    if (selected === undefined) {
      return false;
    }
    const result = await solutionClient.preApproval(selected.solution);
    if (!result.isSuccess) {
      await notifications.publishError("Failed to get pre-approval status.");
      return false;
    }
    return result.value;
  }

  async function submitSolution(): Promise<void> {
    if (selected === undefined) return;

    const preApproved = await getPreApproval();
    setIsDenied(!preApproved);
    if (!preApproved) {
      return;
    }

    const result = await solutionClient.putSolution(selected.solution);
    if (!result.isSuccess) {
      await notifications.publishError("Submission failed.", result.error);
      return;
    }

    // Set Solution
    setSubmittedSolution(selected.solution);

    await notifications.publish(
      new Notification(NotificationLevel.Info, "Submission successful!")
    );
  }

  return {
    selected,
    setSelected,
    submittedSolution,
    setSubmittedSolution,
    context,
    isDenied,
    setIsDenied,
    hasSolutionSelected,
    submitSolution,
  };
};
