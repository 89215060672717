import { RefinanceOptions } from "@uix/common/src/domain/messages/refinance-options";
import {
  formatDollars,
  formatPercent,
} from "@uix/common/src/modules/numberModule";
import { SalesSettings } from "@uix/common/src/domain/messages/sales-settings";
import { useParams } from "react-router-dom";
import { useProductInfo } from "./useProductInfo";
import Pill from "../../Pills/Pill";
import { RefinanceOption } from "@uix/common/src/domain/messages/refinance-option";
import InterestRateFormatter from "../../Formatters/InterestRateFormatter";
import MonthlyPaymentFormatter from "../../Formatters/MonthlyPaymentFormatter";
import TermFormatter from "../../Formatters/TermFormatter";

export interface ProductInfoDisplaySettings {
  showPromotionType?: boolean;
  showLoanToValueRatio?: boolean;
  showTotalInterestIncome?: boolean;
  showTargetVolume?: boolean;
  showEstimatedCommission?: boolean;
}

export interface ProductInfoProps {
  refis: RefinanceOptions | undefined;
  salesSettings: SalesSettings | undefined;
  isSecondItem?: boolean;
  displaySettings?: ProductInfoDisplaySettings;
}

const ProductInfo: React.FC<ProductInfoProps> = (props) => {
  const { refis, salesSettings, isSecondItem } = props;
  const { referenceId } = useParams();

  const userDisplaySettings = {
    ...({
      showPromotionType: true,
      showLoanToValueRatio: true,
      showTotalInterestIncome: true,
      showTargetVolume: true,
      showEstimatedCommission: true,
    } as ProductInfoDisplaySettings),
    ...props.displaySettings,
  };

  /***
   * Logic Starts Here
   */

  const { options, handleChange, refi, selectedOptionReferenceId } =
    useProductInfo({
      refis,
      referenceId,
      isSecondItem,
    });

  const selectedOption =
    options.find((op) => op.referenceId === selectedOptionReferenceId) ||
    options[0];
  /***
   * Logic Ends Here
   */

  const refiLiability = refi?.refinanceLiability;
  const loanAmount = refiLiability?.startingBalance ?? 0;
  const totalTerm = refiLiability?.debtInstrument?.phases.reduce(
    (prev, curr) => prev + (curr.term ?? 0),
    0
  );
  const appraisal = refiLiability?.debtInstrument?.appraisalValue;
  const ltv =
    appraisal !== undefined ? (loanAmount / appraisal) * 100 : undefined;
  const loanCost =
    refiLiability?.debtInstrument?.phases.reduce(
      (prev, curr) => prev + (curr.term ?? 0) * (curr.payment ?? 0),
      0
    ) ?? 0;
  const totalInterest = refiLiability?.totalInterest ?? 0;
  const targetVolume = salesSettings?.currentMonthlySalesTarget ?? 0;
  const commission = (loanAmount * (salesSettings?.commissionRate ?? 0)) / 100;

  const generateNameWithTerm = (refinanceOption: RefinanceOption) => {
    const productTerm =
      refinanceOption.refinanceLiability.debtInstrument?.phases[0].term;
    return `
    ${refinanceOption.refinanceProduct.name} ${
      productTerm !== undefined ? `- ${productTerm} Months` : ""
    }`;
  };

  return (
    <div>
      <div
        className="bg-cc-card-bg px-5 pb-5"
        style={{ borderRightWidth: isSecondItem ? 1 : 0, borderBottomWidth: 1 }}
      >
        <select
          onChange={handleChange}
          value={selectedOptionReferenceId}
          className="select select-bordered select-sm w-full max-w-xs bg-white"
        >
          {options?.map((refi, k) => (
            <option key={k} value={refi.referenceId}>
              {generateNameWithTerm(refi)}
            </option>
          ))}
        </select>
      </div>
      {refi && selectedOption?.description && (
        <div className="flyout-drawer-item-row">
          <h5 className="flyout-drawer-item-title">Description</h5>
          {selectedOption?.description}
        </div>
      )}
      {userDisplaySettings.showPromotionType &&
        refi &&
        selectedOption?._uitype && (
          <div className="flyout-drawer-item-row">
            <h5 className="flyout-drawer-item-title">Promotion Type</h5>
            <Pill refi={refi} type={selectedOption?._uitype} />
          </div>
        )}
      <div className="flyout-drawer-item-row">
        <h5 className="flyout-drawer-item-title">Loan Amount</h5>
        <span className="flyout-drawer-item-value">
          {formatDollars(loanAmount)}
        </span>
      </div>

      <InterestRateFormatter phases={refiLiability?.debtInstrument?.phases} />

      <TermFormatter
        options={options}
        selectedOption={selectedOption}
        handleChange={handleChange}
        selectedOptionReferenceId={selectedOptionReferenceId}
        totalTerm={totalTerm}
      />

      {userDisplaySettings.showLoanToValueRatio && (
        <div className="flyout-drawer-item-row">
          <h5 className="flyout-drawer-item-title">Loan-to-Value Ratio</h5>
          <span className="flyout-drawer-item-value">
            {ltv !== undefined ? formatPercent(ltv) : "(no appraisal)"}
          </span>
        </div>
      )}

      <div className="flyout-drawer-item-row">
        <h5 className="flyout-drawer-item-title">Total Loan Cost</h5>
        <span className="flyout-drawer-item-value">
          {formatDollars(loanCost)}
        </span>
      </div>

      <MonthlyPaymentFormatter phases={refiLiability?.debtInstrument?.phases} />

      {userDisplaySettings.showTotalInterestIncome && (
        <div className="flyout-drawer-item-row">
          <h5 className="flyout-drawer-item-title">Total Interest Income</h5>
          <span className="flyout-drawer-item-value">
            {formatDollars(totalInterest)}
          </span>
        </div>
      )}

      {userDisplaySettings.showTargetVolume && (
        <div className="flyout-drawer-item-row">
          <h5 className="flyout-drawer-item-title">Target Volume</h5>
          <span className="flyout-drawer-item-value">
            {formatDollars(targetVolume)}
          </span>
        </div>
      )}

      {userDisplaySettings.showEstimatedCommission && (
        <div
          className="flyout-drawer-item-row"
          style={{ borderBottomRightRadius: isSecondItem ? "0.75rem" : 0 }}
        >
          <h5 className="flyout-drawer-item-title">
            Estimated Total Commission
          </h5>
          <span className="flyout-drawer-item-value">
            {formatDollars(commission)}
          </span>
        </div>
      )}
    </div>
  );
};

export default ProductInfo;
