import CustomerFinInsights, {
  CustomerFinInsightsDisplaySettings,
} from "./CustomerFinInsights";
import BankerFinInsights from "./BankerFinInsights";
import { SalesSettings } from "@uix/common/src/domain/messages/sales-settings";
import { useState } from "react";
import { isNil } from "lodash";

export enum FinInsightsPage {
  // eslint-disable-next-line no-unused-vars
  Customer = 0,
  // eslint-disable-next-line no-unused-vars
  Banker = 1,
}

export interface FinInsightsProps {
  defaultTab?: FinInsightsPage;
  salesSettings?: SalesSettings;
  customerDisplaySettings: CustomerFinInsightsDisplaySettings;
}

export const FinInsights: React.FC<FinInsightsProps> = ({
  defaultTab,
  salesSettings,
  customerDisplaySettings,
}) => {
  const [page, setPage] = useState<FinInsightsPage>(
    !isNil(defaultTab) ? defaultTab : FinInsightsPage.Banker
  );

  function showCustomer() {
    setPage(FinInsightsPage.Customer);
  }
  function showBanker() {
    setPage(FinInsightsPage.Banker);
  }

  return (
    <>
      <div role="tablist" className="tabs tabs-boxed my-6 dark:bg-inherit">
        {salesSettings && (
          <>
            <a
              role="tab"
              className={`tab ${
                page === FinInsightsPage.Customer
                  ? "tab-active tab-override"
                  : ""
              }`}
              onClick={showCustomer}
            >
              <h5>Customer</h5>
            </a>
            <a
              role="tab"
              className={`tab ${
                page === FinInsightsPage.Banker ? "tab-active tab-override" : ""
              }`}
              onClick={showBanker}
            >
              <h5>Banker</h5>
            </a>
          </>
        )}
      </div>

      {page === FinInsightsPage.Customer && (
        <CustomerFinInsights
          title={customerDisplaySettings.title}
          dtiUnderLimitValidationMessage={
            customerDisplaySettings.dtiUnderLimitValidationMessage
          }
          dtiOverLimitValidationMessage={
            customerDisplaySettings.dtiOverLimitValidationMessage
          }
        />
      )}
      {page === FinInsightsPage.Banker && salesSettings && (
        <BankerFinInsights salesSettings={salesSettings} />
      )}
    </>
  );
};

export default FinInsights;
