import { Result } from "../domain/result";
import { AuthService } from "./authService";

/**
 * Common functionality for implementing any kind of HTTP client.
 */
export abstract class BaseClient {
  /** The value of the Authorization header to send with every request. */
  private authzHeader: string | undefined;
  /** The default MIME type to send in the Accept and Content-Type headers. */
  private readonly defaultMime: string;
  /**
   * A promise which resolves to the bearer token. Note that this is set to `undefined`
   * _after_ it resolves, so as to avoid awaiting the promise repeatedly.
   */
  private bearerToken: Promise<string> | undefined;

  constructor(
    /** The base URI to use for all requests sent by this client. */
    private readonly baseUri: string,
    authService?: AuthService,
    private readonly apiKey?: string,
    options?: HttpOptions
  ) {
    if (baseUri.endsWith("/")) baseUri = baseUri.slice(0, baseUri.length - 1);
    this.baseUri = baseUri;
    this.defaultMime = options?.defaultMime ?? "application/json";
    if (authService) {
      this.bearerToken = authService.authToken();
    }
  }

  public static uriJoin(first: string, second?: string): string {
    if (null === second || undefined === second) return first;
    if (first.endsWith("/")) first = first.slice(0, first.length - 1);
    if (second.startsWith("/")) second = second.slice(1);
    return `${first}/${second}`;
  }

  /** Returns the fully qualified URL to the resource with the given relative path. */
  private uri(relative?: string, queryParams?: IQueryParams): string {
    const joined = BaseClient.uriJoin(this.baseUri, relative);
    return queryParams
      ? `${joined}?${new URLSearchParams(queryParams)}`
      : joined;
  }

  /**
   * Includes the `Authorization` header in the request and sets default values for other headers.
   * @param request
   */
  private modifyHeaders(request: Request): void {
    if (this.authzHeader)
      request.headers.append(HttpHeaderNames.Authorization, this.authzHeader);

    if (this.apiKey) {
      request.headers.append("ApiKey", this.apiKey);
    }

    if ("GET" == request.method) {
      if (!request.headers.has(HttpHeaderNames.Accept))
        request.headers.append(HttpHeaderNames.Accept, this.defaultMime);
    }
    if ("PUT" === request.method || "POST" === request.method) {
      request.headers.set(HttpHeaderNames.ContentType, this.defaultMime);
    }
  }

  /**
   * Awaits the bearer token and assigns a value containing it to the `authzHeader` property.
   * @returns A promise which resolves when `authzHeader` is ready to be used.
   */
  private async initBearerToken(): Promise<void> {
    if (!this.bearerToken) return;

    const token = await this.bearerToken;
    if (!token) return;
    this.authzHeader = `Bearer ${token}`;
    delete this.bearerToken;
  }

  protected async send<T>(request: Request): Promise<Result<T>> {
    await this.initBearerToken();
    this.modifyHeaders(request);
    try {
      const response = await fetch(request);
      if (!response.ok) {
        const body = await response.text();
        try {
          const bodyObj: Object = JSON.parse(body);
          return Result.Error(new HttpError(response.status, bodyObj));
        } catch {
          return Result.Error(new HttpError(response.status, body));
        }
      }
      const body = await response.json();
      // If the body is a result, unwrap it. Otherwise just return a successful result.
      return body.error
        ? Result.Error(body.error)
        : body.value
        ? Result.Success(body.value)
        : Result.Success(body);
    } catch (error) {
      if (error instanceof Error || typeof error === "string")
        return Result.Error(error);
      // If the error is not a string or an `Error` type, we can't handle it.
      throw error;
    }
  }

  protected get(
    relativeUri?: string,
    queryParams?: IQueryParams
  ): Promise<Result<Object>> {
    const uri = this.uri(relativeUri, queryParams);
    const request = new Request(uri, { method: "GET" });
    return this.send(request);
  }

  protected post(
    payload: Object,
    relativeUri?: string,
    queryParams?: IQueryParams
  ): Promise<Result<Object>> {
    const uri = this.uri(relativeUri, queryParams);
    const body = JSON.stringify(payload);
    const request = new Request(uri, { method: "POST", body });
    return this.send(request);
  }

  protected genericPost<T>(
    payload: Object,
    relativeUri?: string,
    queryParams?: IQueryParams
  ): Promise<Result<T>> {
    const uri = this.uri(relativeUri, queryParams);
    const body = JSON.stringify(payload);

    const request = new Request(uri, {
      method: "POST",
      body,
      headers: { accept: "application/json" },
    });
    return this.send(request);
  }

  protected put(
    payload: Object,
    relativeUri?: string,
    queryParams?: IQueryParams
  ): Promise<Result<Object>> {
    const uri = this.uri(relativeUri, queryParams);
    const body = JSON.stringify(payload);
    const request = new Request(uri, { method: "PUT", body });
    return this.send(request);
  }
}

/** Error type which is thrown when a non-successful HTTP status code is received. */
export class HttpError extends Error {
  /** The status code from the HTTP response. */
  public readonly statusCode: number;
  /** The response body. */
  public readonly body: Object;

  constructor(statusCode: number, body: Object) {
    super();
    this.statusCode = statusCode;
    this.body = body;
  }

  public override toString(): string {
    if (typeof this.body === "string")
      return `HTTP Status: ${this.statusCode}, Response body: ${this.body}`;
    else return `HTTP Status: ${this.statusCode}`;
  }
}

/** Collection of name-value pairs to pass along in an HTTP request's query parameters. */
export type IQueryParams = Record<string, string>;

/** The names of standard HTTP headers. */
export enum HttpHeaderNames {
  /**
   * The [Authorization](https://developer.mozilla.org/en-US/docs/Web/HTTP/Headers/Authorization)
   * header.
   */
  Authorization = "Authorization",
  /**
   * The [Accept](https://developer.mozilla.org/en-US/docs/Web/HTTP/Headers/Accept)
   * header.
   */
  Accept = "Accept",
  /**
   * The [Content-Type](https://developer.mozilla.org/en-US/docs/Web/HTTP/Headers/Content-Type)
   * header.
   */
  ContentType = "Content-Type",
}

/** Options to control the behavior of an `HttpClient`. */
export interface HttpOptions {
  /**
   * The default MIME to of messages sent to and from the server.
   */
  defaultMime?: string;
}
