import { Refinance } from "@uix/common/src/domain/messages/refinance";
import {
  interestSavings,
  originalPayment,
  paymentSavings,
} from "@uix/common/src/modules/solutionModule";
import Dollar from "@uix/common/src/components/Dollar";
import MetricChange from "./MetricChange";
import {
  formatDollars,
  formatPercent,
} from "@uix/common/src/modules/numberModule";
import ConsolidationInfo from "./ConsolidationInfo";
import "./SolutionItem.scss";

export interface SolutionItemProps {
  refi: Refinance;
  selected: boolean;
  setSelected: () => void;
}

export const SolutionItem: React.FC<SolutionItemProps> = (props) => {
  const { refi, selected, setSelected } = props;
  const selectedBtnClass = `btn btn-outline border-cc-skyblue text-cc-skyblue solution-item--container-select-button hover:bg-cc-skyblue hover:border-cc-skyblue ${
    selected ? "bg-cc-skyblue text-white" : ""
  }`;

  return (
    <>
      <details className="collapse collapse-arrow bg-white mb-5 py-4 px-6 solution-item--container rounded-md shadow-lg overflow-x-auto">
        <summary className="collapse-title">
          <div className="grid grid-cols-12 gap-5">
            <div className="col-span-4" style={{ borderRightWidth: 1 }}>
              <h3 className="solution-item--container-header">
                {refi.refinanceProduct?.name}
              </h3>
              <span className="solution-item--container-description">
                {refi.description?.length ?? 0 > 45
                  ? refi.description?.slice(0, 45) + "..."
                  : refi.description}
              </span>
            </div>
            <div className="col-span-3" style={{ borderRightWidth: 1 }}>
              <label className="solution-item--container-description">
                Projected Monthly Savings
              </label>
              <div className="solution-item--container-value-label">
                <Dollar value={paymentSavings(refi)} />
              </div>
            </div>
            <div className="col-span-3">
              <label className="solution-item--container-description">
                Projected Interest Savings
              </label>
              <div className="solution-item--container-value-label">
                <Dollar value={interestSavings(refi)} />
              </div>
            </div>
            <div className="col-span-2">
              <div className="flex flex-row justify-center">
                <button
                  onClick={setSelected}
                  className={
                    selectedBtnClass + "  outline-cc-skyblue hover:text-white"
                  }
                >
                  {selected ? "Selected" : "Select"}
                </button>
              </div>
            </div>
          </div>
        </summary>
        <div className="collapse-content pt-3" style={{ borderTopWidth: 1 }}>
          <div>
            {refi.originalLiabilities.length > 1 ? (
              <div style={{ width: "100%" }}>
                <p className="greyed">Current Debt</p>
                {refi.originalLiabilities.map((liability, k) => (
                  <ConsolidationInfo liability={liability} key={k} />
                ))}
                <p className="greyed">Solution</p>
                <ConsolidationInfo liability={refi.refinanceLiability} />
              </div>
            ) : (
              <div className="grid grid-cols-12">
                <div className="col-span-3">
                  <MetricChange
                    name="Outstanding Loan Amount"
                    before={refi.originalLiabilities[0]?.currentBalance ?? 0}
                    after={refi.refinanceLiability.currentBalance ?? 0}
                    template={(value) => <span>{formatDollars(value)}</span>}
                    isImprovement={(before, after) => after < before}
                  />
                </div>

                <div className="col-span-3">
                  <MetricChange
                    name="Term"
                    before={
                      refi.originalLiabilities[0]?.debtInstrument?.phases[0]
                        ?.term ?? 0
                    }
                    after={
                      refi.refinanceLiability.debtInstrument?.phases[0]?.term ??
                      0
                    }
                    template={(value) => <span>{value} months</span>}
                    isImprovement={(before, after) => after > before}
                  />
                </div>

                <div className="col-span-3">
                  <MetricChange
                    name="APR"
                    before={
                      refi.originalLiabilities[0]?.debtInstrument?.phases[0]
                        .nominalInterest ?? 0
                    }
                    after={
                      refi.refinanceLiability.debtInstrument?.phases[0]
                        ?.nominalInterest ?? 0
                    }
                    template={(value) => <span>{formatPercent(value)}</span>}
                    isImprovement={(before, after) => after < before}
                  />
                </div>

                <div className="col-span-3">
                  <MetricChange
                    name="Monthly Payment"
                    before={originalPayment(refi)}
                    after={
                      refi.refinanceLiability.debtInstrument?.phases[0]
                        ?.payment ?? 0
                    }
                    template={(value) => <span>{formatDollars(value)}</span>}
                    isImprovement={(before, after) => after < before}
                  />
                </div>
              </div>
            )}
          </div>
        </div>
      </details>
    </>
  );
};

export default SolutionItem;
