import { useContext } from "react";
import { useOutlet } from "react-router";
import { SalesSettings } from "@uix/common/src/domain/messages/sales-settings";
import { SelectionContext } from "../../../pages/BankerPage/SelectionContext";
import { Alert } from "../../Alert/Alert";
import RefiTable from "../../RefiTable/RefiTable";

export interface EligibleProductsCardProps {
  salesSettings?: SalesSettings;
}

export const EligibleProductsCard: React.FC<EligibleProductsCardProps> = ({
  salesSettings,
}) => {
  const { selected } = useContext(SelectionContext);
  const outlet = useOutlet();
  const refis = [
    ...(selected?.requestedLoan || []),
    ...(selected?.upsellOptions || []),
  ];

  return (
    <>
      <div className="card w-full bg-white shadow-lg rounded-md overflow-x-auto">
        <div className="card-body">
          <h3>Eligible Products</h3>
          {!refis || refis.length > 0 ? (
            <RefiTable
              requestedLoan={selected?.requestedLoan}
              refis={refis}
              salesSettings={salesSettings}
              selectable={true}
            />
          ) : (
            <Alert>
              <p>
                No options currently available for this applicant. Select
                UnderwriteX solution items below for improved eligibility.
              </p>
            </Alert>
          )}
        </div>
      </div>
      {outlet}
    </>
  );
};

export default EligibleProductsCard;
