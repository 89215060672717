/** Functions for working with `CreditScores`. */

import { CreditScores } from "../domain/messages/credit-scores";

/**
 * Returns the average of the given credit scores. If a score from a bureau is missing,
 * it will be ignored. If there are no scores present, then `null` is returned.
 */
export function creditScoresAverage(
  creditScores: CreditScores | undefined
): number | undefined {
  if (undefined === creditScores) return undefined;
  const { experianScore, transUnionScore, equifaxScore } = creditScores;
  const scores = [experianScore, transUnionScore, equifaxScore];
  const presentScores = scores.filter((e) => undefined !== e) as number[];
  if (presentScores.length === 0) return undefined;
  return (
    presentScores.reduce((prev, curr) => prev + curr, 0) / presentScores.length
  );
}
