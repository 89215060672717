import { createBrowserRouter } from "react-router-dom";
import { PagesLayout } from "../pages/PagesLayout";
import DebugPageComponent from "../pages/DebugPage/DebugPage";
import { faRectangleList } from "@fortawesome/free-solid-svg-icons";
import { flyoutRoutes, utils, WithFlyoutNavigation } from "@ucl/library";
import BankerPage from "../pages/BankerPage/BankerPage";
import CompareProducts from "../components/Flyouts/CompareProducts/CompareProducts";
import { injector } from "../injector";
import { Environment } from "@uix/common";
import { EnvName } from "@uix/common/src/environment";
import DecisionPage from "../pages/DecisionPage/DecisionPage";
import ConsumerPage from "../pages/ConsumerPage/ConsumerPage";
import {
  bankerPageRouteId,
  consumerPageRouteId,
  underwritePageLoader,
} from "../pages/useUnderwritePageLoaderData";

const env = injector.get(Environment);

export interface RouteParams {
  childId: string;
  parentId: string;
}

export const uclRouteObject = [
  {
    path: "",
    label: "Home",
    icon: faRectangleList,
    isAvailable: true,
    showInNavigation: true,
    showInSubNavigation: true,
    element: <PagesLayout redirectPath="underwritex" />,
    children: [
      {
        path: "underwritex/:thirdPartyKey?",
        loader: ({ params }) => underwritePageLoader(params, false),
        label: "UnderwriteX",
        id: bankerPageRouteId,
        isAvailable: true,
        showInNavigation: true,
        showInSubNavigation: true,
        element: <BankerPage />,
        children: [
          flyoutRoutes({
            redirectPath: () => "..",
            routes: [
              {
                path: "compare/:referenceId",
                isAvailable: true,
                showInNavigation: false,
                showInSubNavigation: false,
                element: (
                  <WithFlyoutNavigation redirectPath="../..">
                    {(close) => (
                      <CompareProducts
                        pageRouteId={bankerPageRouteId}
                        close={close}
                      />
                    )}
                  </WithFlyoutNavigation>
                ),
              },
            ],
          }),
        ],
      },
      // Direct Consumer Page
      {
        path: `underwritex/:thirdPartyKey?/${consumerPageRouteId}`,
        loader: ({ params }) => underwritePageLoader(params, true),
        label: "Consumer",
        id: consumerPageRouteId,
        isAvailable: false,
        showInNavigation: false,
        showInSubNavigation: false,
        element: <ConsumerPage />,
        children: [
          flyoutRoutes({
            redirectPath: () => "..",
            routes: [
              {
                path: "compare/:referenceId",
                isAvailable: true,
                showInNavigation: false,
                showInSubNavigation: false,
                element: (
                  <WithFlyoutNavigation redirectPath="../..">
                    {(close) => (
                      <CompareProducts
                        pageRouteId={consumerPageRouteId}
                        close={close}
                        displaySettings={{
                          showPromotionType: false,
                          showLoanToValueRatio: false,
                          showTotalInterestIncome: false,
                          showTargetVolume: false,
                          showEstimatedCommission: false,
                        }}
                      />
                    )}
                  </WithFlyoutNavigation>
                ),
              },
            ],
          }),
        ],
      },
      // Temporary decision page to Decision Component
      {
        path: "underwritex/:thirdPartyKey?/decision",
        loader: ({ params }) => underwritePageLoader(params, false),
        label: "Decision",
        id: "decision",
        isAvailable: false,
        showInNavigation: false,
        showInSubNavigation: false,
        element: <DecisionPage />,
      },
    ],
  },
] as utils.routes.UclRouteObject[];

// Add the debug page if we're running in the local environment.
if (env.envName === EnvName.LOCAL) {
  uclRouteObject[0]?.children?.push({
    path: "debug",
    label: "Debug",
    isAvailable: true,
    showInNavigation: false,
    showInSubNavigation: true,
    element: <DebugPageComponent />,
  });
}

export const browserRouter = createBrowserRouter(uclRouteObject, {
  basename: process.env.REACT_APP_PATH_PREFIX,
});
