import { AmortizationPhase } from "@uix/common/src/domain/messages/amortization-phase";
import { formatDollars } from "@uix/common/src/modules/numberModule";
import React from "react";

export interface MonthlyPaymentFormatterProps {
  phases: AmortizationPhase[] | undefined;
}

const MonthlyPaymentFormatter: React.FC<MonthlyPaymentFormatterProps> = ({
  phases,
}) => {
  if (phases === undefined) return <></>;

  const isAdjustable = phases.length > 1;
  const firstPhase = phases[0];

  let formattedPayment;

  if (isAdjustable) {
    const secondPhase = phases[1];
    formattedPayment = (
      <>
        <div className="flyout-drawer-item-row">
          <h5 className="flyout-drawer-item-title">
            Fixed Term Est. Monthly Payment
          </h5>
          <span className="flyout-drawer-item-value">
            {formatDollars(firstPhase.payment)}
          </span>
        </div>
        <div className="flyout-drawer-item-row">
          <h5 className="flyout-drawer-item-title">
            Variable Term Est. Monthly Payment
          </h5>
          <span className="flyout-drawer-item-value">
            {formatDollars(secondPhase.payment)}
          </span>
        </div>
      </>
    );
  } else {
    formattedPayment = (
      <div className="flyout-drawer-item-row">
        <h5 className="flyout-drawer-item-title">Estimated Monthly Payment</h5>
        <span className="flyout-drawer-item-value">
          {formatDollars(firstPhase.payment)}
        </span>
      </div>
    );
  }

  return formattedPayment;
};

export default MonthlyPaymentFormatter;
