/**
 * This module contains code which solves all your problems!
 *
 * JK, it actually just contains code which relates to the {@link Solution} DTO.
 * and the types which it contains.
 */

import { Refinance } from "../domain/messages/refinance";
import { LoanGrouping } from "../domain/messages/loan-grouping";
import { AmortizationPhase } from "../domain/messages/amortization-phase";
import { Solution } from "../domain/messages/solution";
import { Liability } from "../domain/messages/liability";
import { RefinanceOption } from "../domain/messages/refinance-option";
import { liabilityHash, liabilityHashes, stringHash } from "./liabilityModule";
import { Product } from "../domain/messages/product";
import { combineHashes } from "./numberModule";

/**
 * Returns true if the the given {@link Refinance} is taking one or more liabilities into the same
 * {@link LoanGrouping}.
 * @param refi
 */
export function sameCategory(refi: Refinance): boolean {
  // A consolidation should have all of the original liabilities in the same category,
  // so we just look at the first one.
  const before = refi.originalLiabilities[0].debtInstrument?.loanGroup;
  const after = refi.refinanceLiability.debtInstrument?.loanGroup;
  return undefined !== before && undefined !== after && before === after;
}

/**
 * Returns true if the given {@link Refinance} is considered an upsell.
 *
 * The specification for this method is in cell M135 on page "Data" in
 * {@link https://relativitytech-my.sharepoint.com/:x:/p/brendan_reilly/Ea6SICDuMZdHrGu9JCL6gj8BPKu5WdcVjEV3Fw01a6Qv8A?rtime=TGgDrqNR3Eg RelativityX DPI}
 * @param refi
 * @returns
 */
export function isUpsell(
  refi: Refinance,
  requestedLoan: readonly Refinance[]
): boolean {
  const sameAsRequestedLoan =
    liabilityHashes(
      requestedLoan?.reduce((result: Liability[], current: Refinance) => {
        return [...result, ...current.originalLiabilities];
      }, [] as Liability[]) || []
    ) == liabilityHashes(refi.originalLiabilities);

  const monthlySavings = paymentSavings(refi);
  return sameAsRequestedLoan && monthlySavings < 0;
}

/**
 * Returns true if the given {@link Refinance} is considered an cross-sell.
 *
 * The specification for this method is in cell M136 on page "Data" in
 * {@link https://relativitytech-my.sharepoint.com/:x:/p/brendan_reilly/Ea6SICDuMZdHrGu9JCL6gj8BPKu5WdcVjEV3Fw01a6Qv8A?rtime=TGgDrqNR3Eg RelativityX DPI}
 * @param refi
 * @returns
 */
export function isCrossSell(
  refi: Refinance,
  requestedLoan: readonly Refinance[]
): boolean {
  const sameAsRequestedLoan =
    liabilityHashes(
      requestedLoan?.reduce((result: Liability[], current: Refinance) => {
        return [...result, ...current.originalLiabilities];
      }, [] as Liability[]) || []
    ) == liabilityHashes(refi.originalLiabilities);
  //const monthlySavings = paymentSavings(refi);
  //const totalSavings = interestSavings(refi);
  //const hasSavings = monthlySavings > 0 || totalSavings > 0;
  return !sameAsRequestedLoan;
}

/**
 * Returns the total amount paid each month for the original liabilities in the given
 * {@link Refinance}.
 * @param refi
 */
export function originalPayment(refi: Refinance): number {
  return refi.originalLiabilities.reduce(
    (prev, curr) => (prev += curr.debtInstrument?.phases[0]?.payment ?? 0),
    0
  );
}

/**
 * Returns the amount that is saved each month by exercising the given {@link Refinance} option.
 * When the returned number is positive, the new liability has a lower payment than the sum of
 * the original liabilities. Only the payments during the initial {@link AmortizationPhase} are
 * considered.
 * @param refi
 */
export function paymentSavings(refi: Refinance): number {
  const before = originalPayment(refi);
  const after = refi.refinanceLiability.debtInstrument?.phases[0]?.payment ?? 0;
  return before - after;
}

/**
 * Returns the amount of interest which is saved by exercising the given {@link Refinance} option.
 * If the returned number is positive, the new liability has a lower total interest cost than the
 * sum of the original liabilities.
 * @param refi
 * @returns
 */
export function interestSavings(refi: Refinance): number {
  const before = refi.originalLiabilities.reduce(
    (prev, curr) => (prev += curr.totalInterest ?? 0),
    0
  );
  const after = refi.refinanceLiability.totalInterest ?? 0;
  return before - after;
}

/** Returns the amount by which the interest rate changes by exercising the given
 * {@link Refinance} option. A positive number is returned when the new interest rate
 * is greater than the old. If multiple original liabilities are being refinanced, then
 * their average is used.
 */
export function interestRateChange(refi: Refinance): number {
  const sum = refi.originalLiabilities.reduce(
    (prev, curr) =>
      (prev += curr.debtInstrument?.phases[0]?.nominalInterest ?? 0),
    0
  );
  const before =
    refi.originalLiabilities.length > 0
      ? sum / refi.originalLiabilities.length
      : 0;
  const after =
    refi.refinanceLiability.debtInstrument?.phases[0]?.nominalInterest ?? 0;
  return after - before;
}

/**
 * Returns the total monthly gross income of the primary and secondary applicants.
 * @param solution
 */
export function monthlyGrossIncome(solution: Solution): number {
  const primary =
    solution.primaryApplicant.financialInfo?.yearlyGrossIncome?.totalIncome ??
    0;
  const secondary =
    solution.secondaryApplicant?.financialInfo?.yearlyGrossIncome
      ?.totalIncome ?? 0;
  const yearly = primary + secondary;
  return yearly / 12;
}

/**
 * Returns the DTI which will result if all of the refinance options in the given
 * {@link Solution} are exercised.
 * @param solution
 * @returns
 */
export function dti(solution: Solution): number {
  const debt = solution.items.reduce(
    (prev, curr) =>
      prev + (curr.refinanceLiability.debtInstrument?.phases[0]?.payment ?? 0),
    0
  );
  const existingDebt =
    solution?.primaryApplicant.liabilities.reduce((totalDebt, liability) => {
      const isLiabilityInItems = solution?.items.some((item) =>
        item?.originalLiabilities?.some((x) => x.id === liability?.id)
      );
      if (!isLiabilityInItems) {
        totalDebt += liability?.debtInstrument?.phases?.at(0)?.payment ?? 0;
      }
      return totalDebt;
    }, 0) ?? 0;
  const income = monthlyGrossIncome(solution);
  const ratio = income !== 0 ? (debt + existingDebt) / income : 1;
  return 100 * ratio;
}

/**
 * Returns the total amount of new loans originated in the given {@link Solution}.
 * @param solution
 */
export function totalSales(solution: Solution): number {
  return solution.items.reduce((prev, curr) => prev + salesFromRefi(curr), 0);
}

/**
 * Returns the total interest income which the current financial institution will receive if
 * all of the refinancing options in the given {@link Solution} are exercised and all the new
 * liabilities are amortized as projected (no early payoff, refi, etc).
 * @param solution
 */
export function totalInterest(solution: Solution): number {
  return solution.items.reduce(
    (prev, curr) => prev + (curr.refinanceLiability?.totalInterest ?? 0),
    0
  );
}

/**
 * Creates a {@link Refinance} which contains all of the properties of `option` plus the
 * given {@link Liability} array its `originalLiabilities`.
 * @param originalLiability
 * @param option
 * @returns
 */
export function refinanceFrom(
  originalLiabilities: Liability[],
  option: RefinanceOption
): Refinance {
  return {
    originalLiabilities,
    ...option,
  };
}

/**
 * Returns the amount that the given {@link Refinance} contributes to loan sales.
 * @param refi
 * @returns
 */
export function salesFromRefi(refi: Refinance): number {
  return refi.refinanceProduct
    ? refi.refinanceLiability.startingBalance ?? 0
    : 0;
}

/**
 * Returns a function which calculates the estimated conversion rate from the index of a
 * refinance option in an array with `length` elements, which has been sorted by its rank
 * property.
 */
export function conversionRateFunction(
  length: number
): (index: number) => number {
  if (1 === length) {
    return () => 90;
  }
  const coefficient = 80 / (length - 1);
  return (x) => 90 - coefficient * x;
}

/**
 * Returns the total current balance of all of the original liabilities in the given
 * {@link Refinance}.
 * @param refi
 * @returns
 */
export function originalLoanAmount(refi: Refinance): number {
  return refi.originalLiabilities.reduce(
    (prev, curr) => prev + (curr.currentBalance ?? 0),
    0
  );
}

/**
 * Sorting function which allows an array of {@link Refinance} to be sorted by the `rank`
 * property in descending order.
 */
export function byDescendingRank(left: Refinance, right: Refinance): number {
  return right.rank - left.rank;
}

/**
 * Returns a magical number which in some way captures the "identity" of the given
 * {@link Refinance} object.
 */
export function refinanceHash(refi: Refinance): number {
  function* hashCodes(): Iterable<number> {
    yield liabilityHashes(refi.originalLiabilities);
    yield liabilityHash(refi.refinanceLiability) ?? 0;
    yield productHash(refi.refinanceProduct) ?? 0;
  }
  return combineHashes(hashCodes());
}

export function productHash(input: Product | undefined): number | undefined {
  if (input === undefined) return undefined;

  const product = input;
  function* hashCodes(): Iterable<number> {
    yield product.id ?? 0;
    yield stringHash(product.name) ?? 0;
    yield stringHash(product.group) ?? 0;
    yield stringHash(product.productSheet) ?? 0;
  }
  return combineHashes(hashCodes());
}
