import { Refinance } from "@uix/common/src/domain/messages/refinance";
import {
  interestRateChange,
  paymentSavings,
} from "@uix/common/src/modules/solutionModule";
import { Popover } from "react-tiny-popover";
import { useState } from "react";

export interface PillProps {
  refi: Refinance;
  type: "recommended" | "upsell" | "cross-sell";
}

export const Pill: React.FC<PillProps> = (props) => {
  let popoverContent = <></>;
  if (props.type === "recommended") {
    popoverContent = (
      <div className="segmented-list">
        <p>Best fit for affordability and credit profile</p>
        <p>Lowest estimated monthly payment</p>
      </div>
    );
  } else if (props.type === "upsell") {
    popoverContent = (
      <div className="segmented-list">
        <p>Fits within the allowable DTI threshold</p>
        <p>Lower interest rate</p>
      </div>
    );
  } else if (props.type === "cross-sell") {
    const rateChange = interestRateChange(props.refi);
    const paymentChange = paymentSavings(props.refi);
    popoverContent = (
      <div className="segmented-list">
        <p>{rateChange < 0 && "Lower interest rate than existing loan"}</p>
        <p>{paymentChange > 0 && "Lower monthly payment than existing loan"}</p>
      </div>
    );
  }

  const typeStyles = {
    recommended: {
      bg: "bg-cc-skyblue-light",
      text: "text-cc-skyblue",
    },
    upsell: {
      bg: "bg-cc-purple-light",
      text: "text-cc-purple",
    },
    // update these values from figma later
    "cross-sell": {
      bg: "bg-cc-pink-light",
      text: "text-cc-pink",
    },
  };

  const [isPopoverOpen, setIsPopoverOpen] = useState(false);

  return (
    <Popover
      isOpen={isPopoverOpen}
      positions={["bottom"]}
      content={
        <div
          className="menu p-3 shadow-xl bg-base-100 rounded-md w-76 text-white body1 mt-2"
          style={{ backgroundColor: "#0e1624" }}
        >
          {popoverContent}
        </div>
      }
    >
      <div
        onMouseEnter={() => setIsPopoverOpen(true)}
        onMouseLeave={() => setIsPopoverOpen(false)}
        role="button"
        className={`w-fit rounded-full px-4 py-1 flex items-center ${
          typeStyles[props.type].bg
        } ${typeStyles[props.type].text}`}
      >
        <span className="body1">
          {props.type.charAt(0).toUpperCase() + props.type.slice(1)}
        </span>
      </div>
    </Popover>
  );
};

export default Pill;
