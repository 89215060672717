import { SecondaryButton } from "@ucl/library";
import { SecondaryButtonProps } from "@ucl/library/lib/components/Buttons/SecondaryButton";
import { useState } from "react";
import { wrapClickHandler } from "./buttonsModule";

export interface SecondaryButtonAsyncProps
  extends Omit<SecondaryButtonProps, "onClick"> {
  onClick: (event: React.MouseEvent<HTMLElement>) => Promise<void>;
}

export const SecondaryButtonAsync: React.FC<SecondaryButtonAsyncProps> = (
  props
) => {
  const [isLoading, setLoading] = useState(false);
  const onClick = wrapClickHandler(props.onClick, setLoading);
  return SecondaryButton({ ...props, onClick, isLoading });
};

export default SecondaryButtonAsync;
