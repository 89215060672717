import { PublicClientApplication, IPublicClientApplication, Configuration as MsalConfig, LogLevel, AuthenticationResult } from "@azure/msal-browser";
import { Injectable } from "injection-js";
import { Environment } from "../environment";

@Injectable()
export class AuthService {
    private readonly client: Promise<IPublicClientApplication>;
    private readonly config: AuthConfig
    private readonly authPromise: Promise<AuthenticationResult>;

    constructor(
        env: Environment,
    ) {
        if (!window.location.pathname.includes("consumer")) {
            const config = env.authConfig();
            this.config = config;
            const msalConfig = ToMsalConfig(this.config);
            const clientPromise = (async () => {
                const client = await PublicClientApplication.createPublicClientApplication(msalConfig);
                const response = await client.handleRedirectPromise();
                if (response?.account) {
                    client.setActiveAccount(response.account);
                }
                return client;
            })();
            this.client = clientPromise;
            this.authPromise = (async () => {
                const client = await clientPromise;
                const request = { scopes: config.scopes };
                try {
                    return await client.acquireTokenSilent(request);
                } catch (error) {
                    console.info(`Redirecting to login page because token request failed with: '${error}'`);
                    await client.loginRedirect(request);
                    throw error;
                }
            })();
        }
    }

    private async authResult(): Promise<AuthenticationResult> {
        return this.authPromise;
    }

    public async authToken(): Promise<string> {
        const result = await this.authResult();
        return result?.accessToken;
    }

    public async claims(): Promise<{[key: string]: string}> {
        const result = await this.authResult();
        return result?.idTokenClaims as {[key: string]: string};
    }
}

export interface AuthConfig {
    redirectUri: string;
    clientId: string;
    authority: string;
    scopes: string[],
}

function ToMsalConfig(auth: AuthConfig): MsalConfig {
    return {
        auth,
        cache: {
            cacheLocation: "sessionStorage",
            storeAuthStateInCookie: false,
        },
        system: {
            loggerOptions: {
                loggerCallback: (level, message, containsPii) => {
                    let logger: (message: string) => void;
                    switch (level) {
                        case LogLevel.Error:
                            if (containsPii)
                                message = "An MSAL error occurred.";
                            logger = console.error;
                            break;
                        case LogLevel.Warning:
                            if (containsPii)
                                message = "An MSAL warning occurred.";
                            logger = console.warn;
                            break;
                        case LogLevel.Info:
                            if (containsPii)
                                message = "MSAL logged PII at the info level.";
                            logger = console.info;
                            break;
                        default:
                            if (containsPii)
                                message = "MSAL logged PII at the verbose level.";
                            logger = console.debug;
                    }
                    logger(message);
                }
            }
        }
    }
}
