import { ReactElement } from "react";
import { Icon } from "@blueprintjs/core";

export interface AlertProps {
  children: ReactElement;
}

/**
 * Displays the given child element with a scary red background and warning sign.
 */
export const Alert: React.FC<AlertProps> = ({ children }) => {
  return (
    <div className="alert-container">
      <Icon icon="warning-sign" color="#d51d3d" />
      {children}
    </div>
  );
};
