import "./App.scss";
import { configure } from "mobx";
import { registerLicense } from "@syncfusion/ej2-base";
import Pages from "./pages";
import { injector } from "./injector";
import { InjectorContext } from "@uix/common/src/services/injector";
import { Environment } from "@uix/common/src/environment";

const env = injector.get(Environment);
registerLicense(env.syncfusionLicenseKey);

function App() {
  configure({
    disableErrorBoundaries: true,
  });
  return (
    <InjectorContext.Provider value={injector}>
      <Pages />
    </InjectorContext.Provider>
  );
}

export default App;
