import ProductInfo, { ProductInfoDisplaySettings } from "./ProductInfo";
import { useUnderwritePageLoaderData } from "../../../pages/useUnderwritePageLoaderData";

export interface CompareProductsProps {
  pageRouteId: string;
  close: () => void;
  displaySettings?: ProductInfoDisplaySettings;
}

export const CompareProducts: React.FC<CompareProductsProps> = (props) => {
  const { pageRouteId, close, displaySettings } = props;

  if (!pageRouteId) {
    throw new Error("pageRouteId is required");
  }
  const { underwriteData } = useUnderwritePageLoaderData(pageRouteId);

  return (
    <div className="p-6 px-3">
      <div
        className="card border-cc-border-gray border-2"
        style={{ borderWidth: 1, borderRightWidth: 0 }}
      >
        <div className="card-body p-0 gap-0">
          <div
            className="bg-cc-card-bg px-5 pt-5 pb-2 rounded-tl-xl rounded-tr-xl -z-10"
            style={{ borderRightWidth: 1 }}
          >
            <h2 className="card-title compare-products-heading">
              Select Products to Compare
            </h2>
          </div>
          <div className="grid grid-cols-10">
            <div className="col-span-5">
              <ProductInfo
                refis={underwriteData?.refis}
                salesSettings={underwriteData?.salesSettings}
                displaySettings={displaySettings}
              />
            </div>
            <div className="col-span-5">
              <ProductInfo
                refis={underwriteData?.refis}
                salesSettings={underwriteData?.salesSettings}
                isSecondItem
                displaySettings={displaySettings}
              />
            </div>
          </div>
        </div>
      </div>

      <div className="mt-5 flex justify-end">
        <button
          onClick={close}
          className="btn btn-primary bg-cc-skyblue border-cc-skyblue hover:border-cc-skyblue hover:bg-cc-skyblue hover:no-underline outline-none"
        >
          <span className="text-white body1">Close</span>
        </button>
      </div>
    </div>
  );
};

export default CompareProducts;
