/**
 * The `Result` and `Result<T>` types serialize to an object with this interface. `Result`
 * corresponds to `IResult<void>`.
 * */
export interface IResult<T> {
  readonly error?: string | Error;
  readonly value?: T;
}

/**
 * Represents the success or failure of an operation. In the case of success, this class contains
 * a value of type `T`. Otherwise, it contains an `Error` explaining why `T` is not present. This
 * error is thrown if an attempt is made to access `value` on an object which is not successful.
 */
export class Result<T> {
  /** If this is result is not successful, this is the error that was encountered. */
  public readonly error?: Error;

  /** Indicates if this result was successful. */
  public get isSuccess(): boolean {
    return !this.error;
  }

  private readonly _value?: T;
  /** The value of this result. If this result is a failure then `error` is thrown. */
  public get value(): T {
    if (this.error) throw this.error;
    else return this._value!;
  }

  constructor(obj: IResult<T>) {
    if (obj.error !== undefined)
      this.error =
        typeof obj.error === "string" ? new Error(obj.error) : obj.error;
    else if (obj.value !== undefined) this._value = obj.value;
    else
      throw new Error(
        "Can't create a `Result` from an object without an `error` or `value` property."
      );
  }

  /** Creates a successful `Result` containing the given value. */
  public static Success<T>(value: T): Result<T> {
    return new Result<T>({ value });
  }

  /** Creates an error `Result` containing the given value. */
  public static Error<T>(error: string | Error): Result<T> {
    return new Result<T>({ error });
  }

  /**
   * If this `Result` is successful, then the given function is applied to its value and a new
   * result is returned which contains the returned value. Otherwise, a new result is returned
   * containing the same error as this result.
   * @param func The function to transform the value of this result with.
   * @returns
   */
  public map<U>(func: (value: T) => U): Result<U> {
    return this.error !== undefined
      ? Result.Error(this.error!)
      : Result.Success(func(this._value!));
  }

  /**
   * Casts the type inside an error result to `U`. If this result is actually an error, this is a
   * purely TypeScript level transformation, and an unmodified JavaScript reference to `this` will
   * is returned. If this results isn't actually an error, a new error result will be returned.
   * @returns
   */
  public castError<U>(): Result<U> {
    return this.error !== undefined
      ? Result.Error(this.error!)
      : Result.Error(new Error("Casted a successful Result."));
  }
}
