import { SalesSettings } from "@uix/common/src/domain/messages/sales-settings";
import RefiTable, { RefiTableDisplaySettings } from "../RefiTable/RefiTable";
import { useContext } from "react";
import { SelectionContext } from "../../pages/BankerPage/SelectionContext";

export interface SelectedProductsCardProps {
  salesSettings: SalesSettings | undefined;
  displaySettings?: RefiTableDisplaySettings;
}

export const SelectedProductsCard: React.FC<SelectedProductsCardProps> = ({
  salesSettings,
  displaySettings,
}) => {
  const { selected } = useContext(SelectionContext);
  const refis = selected?.solution.items;
  return (
    <div className="card w-full bg-white shadow-lg rounded-md overflow-x-auto">
      <div className="card-body">
        <h3>Selected Products</h3>

        <RefiTable
          refis={refis}
          requestedLoan={selected?.requestedLoan}
          salesSettings={salesSettings}
          displaySettings={displaySettings}
        />
      </div>
    </div>
  );
};

export default SelectedProductsCard;
