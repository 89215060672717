/**
 * Code for working with {@link LoanGrouping}s.
 */

import { LoanGrouping } from "../domain/messages/loan-grouping";

const isLowerCaseLetter = (char: string | null): boolean => {
  if (typeof char !== "string" || char === null) return false;
  const codePoint = char.codePointAt(0);
  return codePoint !== undefined && 97 <= codePoint && codePoint <= 122;
};

const isUpperCaseLetter = (char: string | null): boolean => {
  if (typeof char !== "string" || char === null) return false;
  const codePoint = char.codePointAt(0);
  return codePoint !== undefined && 65 <= codePoint && codePoint <= 90;
};

/**
 * Breaks up a PascalCase string so that spaces are inserted
 * between words.
 */
const pascalCaseToSpace = (text: string): string => {
  let prev = null,
    output = "";
  for (const curr of text) {
    if (isLowerCaseLetter(prev) && isUpperCaseLetter(curr)) {
      output += " ";
    }
    prev = curr;
    output += curr;
  }
  return output;
};
/**
 * Returns a name for the given {@link LoanGrouping} which is suitable for displaying to the user.
 * @param loanGroup
 * @returns
 */
export function loanGroupDisplayName(
  loanGroup: LoanGrouping | undefined
): string | undefined {
  if (isLoanGroupMortgage(loanGroup)) {
    return "Mortgage";
  }
  return pascalCaseToSpace(loanGroup?.toString() ?? "");
}

/**
 * Returns true if the given {@link LoanGrouping} is a mortgage.
 * @param loanGroup
 * @returns
 */
export function isLoanGroupMortgage(
  loanGroup: LoanGrouping | undefined
): boolean {
  return (
    LoanGrouping.NewMortgage == loanGroup ||
    LoanGrouping.RefinancedMortgage == loanGroup
  );
}
