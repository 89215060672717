/**
 * Code for working with {@link Liability} objects and the types they contain.
 */
import { AmortizationKind } from "../domain/messages/amortization-kind";
import { AmortizationPhase } from "../domain/messages/amortization-phase";
import { DebtInstrument } from "../domain/messages/debt-instrument";
import { Liability } from "../domain/messages/liability";
import { LocalDate } from "../domain/messages/local-date";
import { combineHashes } from "./numberModule";

export function liabilityHash(
  input: Liability | undefined
): number | undefined {
  if (input === undefined) return undefined;

  const liability = input;
  function* hashCodes(): Iterable<number> {
    yield liability.startingBalance ?? 0;
    yield liability.currentBalance ?? 0;
    yield stringHash(liability.lifeCycle) ?? 0;
    yield debtInstrumentHash(liability.debtInstrument) ?? 0;
  }
  return combineHashes(hashCodes());
}

export function liabilityLiteHash(
  input: Liability | undefined
): number | undefined {
  if (input === undefined) return undefined;

  const liability = input;
  function* hashCodes(): Iterable<number> {
    yield liability.startingBalance ?? 0;
    yield liability.currentBalance ?? 0;
    yield stringHash(liability.lifeCycle) ?? 0;
  }
  return combineHashes(hashCodes());
}

export function liabilityHashes(input: Liability[]): number {
  function* hashCodes(): Iterable<number> {
    for (const liability of input) {
      yield liabilityHash(liability) ?? 0;
    }
  }
  return combineHashes(hashCodes());
}

export function debtInstrumentHash(
  input: DebtInstrument | undefined
): number | undefined {
  if (input === undefined) return undefined;

  const debt = input;
  function* hashCodes(): Iterable<number> {
    yield stringHash(debt.loanGroup) ?? 0;
    for (const phase of debt.phases) {
      yield phaseHash(phase) ?? 0;
    }
  }
  return combineHashes(hashCodes());
}

export function localDateHash(
  input: LocalDate | undefined
): number | undefined {
  if (input === undefined) return undefined;

  const date = input;
  function* hashCodes(): Iterable<number> {
    yield date.year;
    yield date.month;
    yield date.day;
  }
  return combineHashes(hashCodes());
}

export function phaseHash(
  input: AmortizationPhase | undefined
): number | undefined {
  if (input === undefined) return undefined;

  const phase = input;
  function* hashes(): Iterable<number> {
    yield stringHash(phase.kind) ?? 0;
    if (phase.kind === AmortizationKind.Structured) {
      yield phase.term ?? 0;
    }
    yield phase.nominalInterest ?? 0;
    yield phase.payment ?? 0;
    yield phase.interestPeriodsPerYear ?? 0;
    yield phase.paymentPeriodsPerYear ?? 0;
    yield phase.isPromotion ? 1 : 0;
  }
  return combineHashes(hashes());
}

export function stringHash(input: string | undefined): number | undefined {
  if (input === undefined) return undefined;

  const str = input;
  function* charCodes(): Iterable<number> {
    for (let k = 0; k < str.length; k += 1) {
      yield str.charCodeAt(k);
    }
  }
  return combineHashes(charCodes());
}
