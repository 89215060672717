import { Injectable } from "injection-js";
import { AuthService } from "./authService";
import { Environment } from "../environment";
import { BaseClient } from "./baseClient";
import { SettingsKey } from "../domain/messages/settings-key";
import { Result } from "../domain/result";

@Injectable()
export class SettingsClient extends BaseClient {
  constructor(env: Environment, authService: AuthService) {
    super(BaseClient.uriJoin(env.lipBaseUrl, "settings"), authService);
  }

  public getSettings(key: SettingsKey): Promise<Result<Object>> {
    return this.get(undefined, {key});
  }
}