import { PrimaryCard, SecondaryCard } from "@ucl/library";
import { Application } from "@uix/common/src/domain/messages/application";
import { PrimaryButtonAsync } from "@uix/common/src/components/Buttons/PrimaryButtonAsync";
import { SecondaryButtonAsync } from "@uix/common/src/components/Buttons/SecondaryButtonAsync";
import { injector } from "../../injector";
import { ApplicationClient } from "@uix/common";
import { useState } from "react";
import { NotificationsService } from "@uix/common/src/services/notificationService";

const appClient = injector.get(ApplicationClient);
const notificationService = injector.get(NotificationsService);

interface State {
  appId: string;
  appJson: string;
}

function initialState(): State {
  return { appId: "", appJson: "" };
}

const ApplicationTest: React.FC = () => {
  const [state, setState] = useState(initialState());

  async function load(): Promise<void> {
    const { appId } = state;
    if (!appId) return;
    const result = await appClient.getApplication(appId);
    if (!result.isSuccess) {
      const message = `Failed to load application ${appId}: ${result.error}`;
      await notificationService.publishError(message, result.error);
      return;
    }
    const app = result.value;
    const appJson = JSON.stringify(app, null, 2);
    setState({ appId, appJson });
  }

  async function save(): Promise<void> {
    const { appJson } = state;
    if (!appJson) return;
    const app: Application = JSON.parse(appJson);
    const result = await appClient.putApplication(app);
    if (!result.isSuccess) {
      const message = `Failed to save application: ${result.error}`;
      await notificationService.publishError(message, result.error);
      return;
    }
    const appId = result.value;
    setState({ appId, appJson });
  }

  function appIdChange(event: React.FormEvent<HTMLInputElement>): void {
    const { appJson } = state;
    const appId = event.currentTarget.value;
    setState({ appId, appJson });
  }

  function appJsonChange(event: React.FormEvent<HTMLTextAreaElement>): void {
    const { appId } = state;
    const appJson = event.currentTarget.value;
    setState({ appId, appJson });
  }

  return (
    <PrimaryCard headerContent="Application CRUD Test">
      <p>
        Enter an application ID then click the button below to test if the
        connection with backend is working.
      </p>
      <label>Application ID:</label>
      <div className="flexit">
        <input type="text" value={state.appId} onChange={appIdChange} />
        <SecondaryButtonAsync onClick={load} content="Load" />
        <PrimaryButtonAsync
          onClick={save}
          content="Save"
          disabled={!state.appJson}
        />
      </div>
      <SecondaryCard headerContent="Application JSON">
        <textarea
          cols={100}
          rows={30}
          value={state.appJson}
          onChange={appJsonChange}
        ></textarea>
      </SecondaryCard>
    </PrimaryCard>
  );
};

export default ApplicationTest;
