import { Result } from "../domain/result";
import { Application } from "../domain/messages/application";
import { BaseClient, IQueryParams } from "./baseClient";
import { Injectable } from "injection-js";
import { Environment } from "../environment";
import { AuthService } from "./authService";
import { RefinanceOptions } from "../domain/messages/refinance-options";

/**
 * A query an {@link Application}, or a related type. It is an error for both `appId`
 * and `thirdPartyKey` to be null. If both are non-null, `appId` takes priority.
 */
export interface ApplicationQuery {
  /** The internal ID of an application. */
  appId?: string;
  /** The external ID of an application. */
  thirdPartyKey?: string;
}

/**
 * An API client for working with loan applications.
 */
@Injectable()
export class ApplicationClient extends BaseClient {
  constructor(env: Environment, authService: AuthService) {
    super(BaseClient.uriJoin(env.lipBaseUrl, "application"), authService);
  }

  public async getApplication(id: string): Promise<Result<Application>> {
    const result = await this.get(undefined, { id });
    return result as Result<Application>;
  }

  public async putApplication(app: Application): Promise<Result<string>> {
    const result = await this.put(app);
    return result as Result<string>;
  }

  /**
   * Gets the {@link RefinanceOptions} for the application with the given ID.
   *
   * The return value is, essentially, an array of arrays, where each entry in the outer array is
   * the array of refinancing options for a particular liability. In other words, the original
   * liabilities for each entry of an inner array are the same.
   * @param id
   * @returns
   */
  public async getRefis(
    input: ApplicationQuery
  ): Promise<Result<RefinanceOptions>> {
    const { appId, thirdPartyKey } = input;
    if (undefined === appId && undefined === thirdPartyKey) {
      return Result.Error("appId or thirdPartyKey must be supplied.");
    }
    const query: IQueryParams = {};
    if (undefined !== appId) {
      query.appId = appId;
    }
    if (undefined !== thirdPartyKey) {
      query.thirdPartyKey = thirdPartyKey;
    }
    const result = await this.get("refis", query);
    return result as Result<RefinanceOptions>;
  }
}
