import Dollar from "@uix/common/src/components/Dollar";
import { formatPercent } from "@uix/common/src/modules/numberModule";
import {
  dti,
  monthlyGrossIncome,
  productHash,
  refinanceHash,
} from "@uix/common/src/modules/solutionModule";
import { faTriangleExclamation } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { isLoanGroupMortgage } from "@uix/common/src/modules/loanGroupingModule";
import { useContext } from "react";
import { SelectionContext } from "../../../pages/BankerPage/SelectionContext";

/**
 * If the solution has a DTI less than or equal to this threshold, then the green check mark and
 * message is shown at the bottom of this component.
 */
const thresholdDti = 43;

/**
 * An estimated ratio of monthly insurance cost to property value. This based on only a
 * single data point (the author's home), so take it with a grain of salt.
 */
const insuranceToValueRatio = -7.68e-4;

export interface CustomerFinInsightsDisplaySettings {
  title: string;
  dtiUnderLimitValidationMessage: string;
  dtiOverLimitValidationMessage: string;
}
export interface CustomerFinInsightsProps
  extends CustomerFinInsightsDisplaySettings {}

export const CustomerFinInsights: React.FC<CustomerFinInsightsProps> = (
  props
) => {
  const { selected } = useContext(SelectionContext);
  const solution = selected?.solution;

  const monthlyIncome = solution ? monthlyGrossIncome(solution) : 0;
  const requestedProductHash = productHash(
    selected?.recommended?.at(0)?.refinanceProduct
  );

  // Get Requested Loan from the solution
  // This is the loan the user selected on the application, or a new ione the user selected through recommendations, or upsell
  const requestedLoan =
    requestedProductHash !== undefined
      ? solution?.items.find((refi) =>
          refi?.originalLiabilities?.some(
            (x) =>
              x.id ===
              selected?.recommended?.at(0)?.originalLiabilities?.at(0)?.id
          )
        )
      : undefined;

  const requestedLoanGroup =
    requestedLoan?.originalLiabilities?.at(0)?.debtInstrument?.loanGroup;
  const requestedLoanHash = requestedLoan
    ? refinanceHash(requestedLoan)
    : undefined;
  const requestedLoanIsMortgage = isLoanGroupMortgage(requestedLoanGroup);
  const requestedLoanPayment =
    -1 *
    (requestedLoan?.refinanceLiability.debtInstrument?.phases?.at(0)?.payment ??
      0);

  const otherLoans = solution?.items.filter((e) => {
    return requestedLoanHash !== refinanceHash(e);
  });
  const otherLoanPayments =
    otherLoans?.reduce(
      (prev, curr) =>
        prev -
        (curr.refinanceLiability?.debtInstrument?.phases?.at(0)?.payment ?? 0),
      0
    ) ?? 0;

  //Compute the current monthly debt
  // This is the sum of all liability monthly debt payments
  // If a new solution is provided (cross-sell) then we will use the new solution
  const currentMonthlyDebt =
    -1 *
      solution?.primaryApplicant.liabilities.reduce((totalDebt, liability) => {
        const isLiabilityInItems = solution?.items.some((item) =>
          item?.originalLiabilities?.some((x) => x.id === liability?.id)
        );
        if (!isLiabilityInItems) {
          totalDebt += liability?.debtInstrument?.phases?.at(0)?.payment ?? 0;
        }
        return totalDebt;
      }, 0) ?? 0;

  const solutionDti = solution ? dti(solution) : 0;
  const appraisalValue =
    requestedLoan?.originalLiabilities?.at(0)?.debtInstrument?.appraisalValue ??
    0;
  const insurance = requestedLoanIsMortgage
    ? insuranceToValueRatio * appraisalValue
    : 0;
  const remainingIncome =
    monthlyIncome +
    requestedLoanPayment +
    currentMonthlyDebt +
    insurance +
    otherLoanPayments;

  const withinMargin = solutionDti <= thresholdDti;

  return (
    <>
      <h5 className="px-4 pb-3">{props.title}</h5>
      <div className="px-4 py-3 flex flex-row justify-between border-b-2 body1 text-dark-gray1">
        <span>Gross Income</span>
        <Dollar value={monthlyIncome} />
      </div>
      <div className="px-4 py-3 flex flex-row justify-between border-b-2 body1 text-dark-gray1">
        <span>{requestedLoan?.refinanceProduct?.name || "Mortgage"}</span>
        <Dollar value={requestedLoanPayment} />
      </div>
      {otherLoans?.map((refi, i) => {
        return (
          <div
            key={i}
            className="px-4 py-3 flex flex-row justify-between border-b-2 body1 text-dark-gray1"
          >
            <span>{refi.refinanceProduct?.name}</span>
            <Dollar
              value={
                -1 *
                (refi.refinanceLiability.debtInstrument?.phases?.at(0)
                  ?.payment ?? 0)
              }
            />
          </div>
        );
      })}
      <div className="px-4 py-3 flex flex-row justify-between border-b-2">
        <div>
          <span className="body1 text-dark-gray1">Current Monthly Debt</span>
        </div>
        <Dollar value={currentMonthlyDebt} />
      </div>
      {requestedLoanIsMortgage && (
        <div className="px-4 py-3 flex flex-row justify-between border-b-2">
          <span className="body1 text-dark-gray1">Insurance</span>
          <Dollar value={insurance} />
        </div>
      )}
      <div className="px-4 py-3 flex flex-row justify-between border-b-2 body1 text-dark-gray1">
        <span>Debt-to-Income Ratio</span>
        <span>{formatPercent(solutionDti)}</span>
      </div>
      <div className="px-4 py-3 flex flex-row justify-between">
        <span className="body1 text-dark-gray1">Remaining Income</span>
        <Dollar value={remainingIncome} />
      </div>
      {withinMargin ? (
        <div className="px-4 py-3 flex flex-row justify-between">
          <div className="mt-1">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="14"
              height="14"
              viewBox="0 0 14 14"
              fill="none"
            >
              <g clipPath="url(#clip0_1649_6968)">
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M7 0C3.13409 0 0 3.13409 0 7C0 10.8659 3.13409 14 7 14C10.8659 14 14 10.8659 14 7C14 3.13409 10.8659 0 7 0ZM10.0342 5.81636C10.0901 5.7525 10.1326 5.67811 10.1593 5.59756C10.186 5.51702 10.1963 5.43195 10.1896 5.34735C10.183 5.26276 10.1594 5.18036 10.1205 5.10499C10.0815 5.02962 10.0278 4.96281 9.96262 4.90849C9.89744 4.85417 9.82204 4.81343 9.74088 4.78868C9.65972 4.76392 9.57442 4.75565 9.49002 4.76434C9.40561 4.77304 9.32379 4.79853 9.24938 4.83931C9.17497 4.88009 9.10947 4.93535 9.05673 5.00182L6.32036 8.28482L4.90445 6.86827C4.78443 6.75235 4.62369 6.68821 4.45684 6.68966C4.28998 6.69111 4.13037 6.75804 4.01239 6.87602C3.8944 6.99401 3.82748 7.15362 3.82603 7.32047C3.82458 7.48732 3.88872 7.64807 4.00464 7.76809L5.91373 9.67718C5.97625 9.73967 6.05112 9.78845 6.13354 9.8204C6.21596 9.85236 6.30413 9.86679 6.39244 9.86278C6.48075 9.85877 6.56726 9.8364 6.64644 9.7971C6.72563 9.75781 6.79576 9.70245 6.85236 9.63455L10.0342 5.81636Z"
                  fill="#4FC879"
                />
              </g>
              <defs>
                <clipPath id="clip0_1649_6968">
                  <rect width="14" height="14" fill="white" />
                </clipPath>
              </defs>
            </svg>
          </div>
          <span className="body1 ml-1 text-dark-gray1">
            {props.dtiUnderLimitValidationMessage}
          </span>
        </div>
      ) : (
        <div className="px-4 py-3 flex flex-row justify-between">
          <div className="mt-1">
            <FontAwesomeIcon
              icon={faTriangleExclamation}
              style={{ color: "#FFD43B", display: "block" }}
              fill="none"
            />
          </div>
          <span className="body1 ml-1 text-dark-gray1">
            {props.dtiOverLimitValidationMessage}
          </span>
        </div>
      )}
    </>
  );
};

export default CustomerFinInsights;
