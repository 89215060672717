import {
  consumerPageRouteId,
  useUnderwritePageLoaderData,
} from "../useUnderwritePageLoaderData";
import ConsumerComponent from "./ConsumerComponent";

export const ConsumerPage: React.FC = () => {
  const { underwriteData } = useUnderwritePageLoaderData(consumerPageRouteId);

  return (
    <>
      <ConsumerComponent refis={underwriteData.refis} />
    </>
  );
};

export default ConsumerPage;
