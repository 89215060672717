import { ReactElement, useContext, useEffect, useState } from "react";
import { NotificationsService, Notification, NotificationCallback } from "../services/notificationService";
import { NotificationLevel } from "../services/notificationLevel";
import { InjectorContext } from "../services/injector";
import { BlueprintIcons_16Id } from "@blueprintjs/icons/lib/esm/generated-icons/16px/blueprint-icons-16";
import { Icon, Label } from "@blueprintjs/core";
import { Popover2 as Popover } from "@blueprintjs/popover2"
import { dialogStore, CancelButton, PrimaryButton, Footer } from "@ucl/library";

interface NotificationProps {
  value: Notification;
}

enum Color {
  BadRed = "#ac2f33",
  Red = "#cd4246",
  Orange = "#ec9a3c",
  Blue = "#2d72d2",
  Green = "#165a36",
}

const NotificationDisplay: React.FC<NotificationProps> = (props: NotificationProps) => {
  const { value } = props;
  let icon: ReactElement;
  switch (value.level) {
    case NotificationLevel.Fatal:
      icon = <Icon color={Color.BadRed} icon="cross-circle"/>
      break;
    case NotificationLevel.Error:
      icon = <Icon color={Color.Red} icon="error"/>;
      break;
    case NotificationLevel.Warn:
      icon = <Icon color={Color.Orange} icon="warning-sign"/>;
      break;
    case NotificationLevel.Info:
      icon = <Icon color={Color.Blue} icon="info-sign"/>;
      break;
    case NotificationLevel.Debug:
      icon = <Icon color={Color.Green} icon="build"/>;
      break;
    default:
      throw new Error(`Unexpected NotificationLevel: '${value.level}'.`);
  }
  return <div>
    {icon}
    <span>&nbsp;{value.message}</span>
  </div>
};

interface State {
  current?: Notification;
  notifications: ReadonlyArray<Notification>,
}

function initialState(): State {
  return { notifications: [] };
}

/**
 * A void returning promise which is completed. A single instance is cached to avoid creating
 * garbage.
 */
const completed: Promise<void> = new Promise(resolve => resolve());

/**
 * The number of milliseconds a notification is displayed when it's published.
 */
const notificationDisplayMs = 5000;

/**
 * The ID of the notifications dialog.
 */
const dialogId = "Notifications";

export const Notifications: React.FC = () => {
  const [state, setState] = useState(initialState());
  const injector = useContext(InjectorContext);
  const notificationsService = injector?.get(NotificationsService);

  if (!notificationsService)
    throw new Error("The Notifications component can't function without the NotificationsService.");
  const { notifications } = state;

  const openDialog = () => {
    dialogStore.openDialog({
      id: dialogId,
      header: "Notifications",
      allowDragging: false,
      position: { X: "right", Y: "top" },
      height: notifications.length * 60,
      minHeight: 200,
      content: () => {
        return notifications.length > 0
          ? <>{notifications.map(e => <NotificationDisplay value={e}/>)}</>
          : <p>No notifications.</p>
      },
      footerTemplate: () => {
        return <Footer>
          <PrimaryButton
            onClick={() => dialogStore.closeDialog(dialogId)}
            content="Close"
          />
          <CancelButton
            onClick={() => {
              notificationsService.clearNotifications();
              setState({ notifications: [] });
              dialogStore.closeDialog(dialogId);
            }}
            content="Clear"
          />
        </Footer>
      },
    })
  }

  const handleNotification: NotificationCallback = current => {
    const notifications = notificationsService.getNotifications();
    setState({ current, notifications });
    setTimeout(
      () => {
        const notifications = notificationsService.getNotifications();
        setState({ notifications });
      },
      notificationDisplayMs
    );
    return completed;
  }

  useEffect(() => {
    notificationsService.register(handleNotification);
    return () => notificationsService.deregister(handleNotification);
  });

  const icon: BlueprintIcons_16Id = notifications.length > 0
    ? "notifications-updated"
    : "notifications";
  
  return <Popover
    placement="left"
    isOpen={!!state.current}
    content={state.current ? <NotificationDisplay value={state.current}/> : <></> }
  >
    <Label
      className="label-clickable"
      onClick={openDialog}
    >
      <Icon icon={icon} />
    </Label>
  </Popover>
}