/**
 * Formats a number as a string.
 * @param value The number to format.
 * @param decimalPlaces The number of decimal places to include.
 * @param decimalSeparator The character to separate the decimal digits with.
 * @param thousandsSeparator The character to separate every group of three digits.
 * @returns
 */
export function formatNumber(
  value: number,
  decimalPlaces: number = 2,
  decimalSeparator: string = ".",
  thousandsSeparator: string = ","
): string {
  if (0 === value) {
    const chars = ["0"];
    if (decimalPlaces > 0) {
      chars.push(decimalSeparator);
      for (let k = 0; k < decimalPlaces; k += 1) {
        chars.push("0");
      }
    }
    return chars.join("");
  }

  const isNeg = value < 0;
  value = Math.abs(value);
  if (Infinity === value) {
    return "Infinity";
  }
  let test = Math.round(10 ** decimalPlaces * value);
  let placeValue = decimalPlaces * -1;
  const chars = [];
  while (test > 0) {
    if (0 === placeValue) {
      chars.push(decimalSeparator);
    } else if (0 === placeValue % 3) {
      chars.push(thousandsSeparator);
    }
    const digit = test % 10;
    chars.push(digit.toString());
    test = Math.floor(test / 10);
    placeValue += 1;
  }
  if (chars[0] === decimalSeparator) {
    chars.splice(0, 1);
  }
  return `${isNeg ? "-" : ""}${chars.reverse().join("")}`;
}

/** Formats the given number of dollars as string. */
export function formatDollars(value: number): string {
  const isNeg = value < 0;
  const formatted = formatNumber(Math.abs(value), 2, ".", ",");
  return `${isNeg ? "-" : ""}\$${formatted}`;
}

/** Formats the given value as a percentage with the given number of decimal places. */
export function formatPercent(
  value: number,
  decimalPlaces: number = 2
): string {
  const formatted = formatNumber(value, decimalPlaces, ".", ",");
  return `${formatted}%`;
}

/** Combines the given hashes into a single value using magic numbers (primes). */
export function combineHashes(hashCodes: Iterable<number>): number {
  let hash = 17;
  for (let hashCode of hashCodes) {
    // 37 is the first irregular prime, but that is totally irrelevant to this calculation.
    hash += 37 * hashCode;
  }
  return hash;
};
