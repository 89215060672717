import { Solution } from "@uix/common/src/domain/messages/solution";
import SolutionItem from "./SolutionItem/SolutionItem";
import { useContext, useState } from "react";
import {
  SelectedSolution,
  SelectionContext,
} from "../../../pages/BankerPage/SelectionContext";
import { Button } from "../../Button/Button";

export interface ApprovalSolutionProps {
  solution: Solution | undefined;
}

export const ApprovalSolution: React.FC<ApprovalSolutionProps> = (props) => {
  const { solution } = props;
  const { selected, setSelected } = useContext(SelectionContext);
  const [allSelected, setAllSelected] = useState(false);

  function toggleSelection(): void {
    if (!solution || !selected || !setSelected) return;
    // It's important to clone `solution`, because SelectedSolution
    // will modify it.
    const clonedSolution = structuredClone(solution);
    if (allSelected) {
      // Deselect all
      clonedSolution.items = [];
    }
    setSelected(
      new SelectedSolution({
        ...selected,
        solution: clonedSolution,
      })
    );
    // Toggle the allSelected state
    setAllSelected(!allSelected);
  }

  return (
    <>
      <div className="flex flex-row justify-between items-center mt-11 mb-2">
        <h2>UnderwriteX Approval Solution</h2>

        <Button
          onClick={toggleSelection}
          label={allSelected ? "Deselect All" : "Select All"}
        ></Button>
      </div>
      {solution?.items?.map((refi, k) => (
        <SolutionItem
          key={k}
          refi={refi}
          selected={selected?.isSelected(refi) ?? false}
          setSelected={() => selected?.toggle(refi, setSelected)}
        />
      ))}
    </>
  );
};

export default ApprovalSolution;
